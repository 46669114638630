<template>
    <div class="toolbar">
        <div class="toolbar_general">
            <div class="toolbar-item"
                 :class="{active: isActivePage(PAGES.ALL_STREAMS)}">
                <router-link class="link-ycc"
                             :to="{name: PAGES.ALL_STREAMS}">
                    <div class="toolbar-item_icon">
                        <span class="icon-ycc">
                            <i class="material-icons">videocam</i>
                        </span>
                    </div>

                    <span class="toolbar-item_title"
                          v-translate>All streams</span>
                </router-link>

                <div class="toolbar-item_menu"
                     :class="{hidden: isOpenAside}">
                    <router-link class="link-ycc"
                                 :to="{name: PAGES.ALL_STREAMS}">
                        <div class="menu-item"
                             v-translate>
                            All streams
                        </div>
                    </router-link>
                </div>
            </div>

            <div class="toolbar-item"
                 v-if="!isReadOnlyProfile"
                 :class="{active: isActivePage(PAGES.ADD_STREAM)}">
                <router-link class="link-ycc"
                             :to="{name: PAGES.ADD_STREAM}">
                    <div class="toolbar-item_icon">
                        <span class="icon-ycc">
                            <i class="material-icons">add</i>
                        </span>
                    </div>

                    <span class="toolbar-item_title"
                          v-translate>Add stream</span>
                </router-link>

                <div class="toolbar-item_menu"
                     :class="{hidden: isOpenAside}">
                    <router-link class="link-ycc"
                                 :to="{name: PAGES.ADD_STREAM}">
                        <div class="menu-item"
                             v-translate>
                            Add stream
                        </div>
                    </router-link>
                </div>
            </div>

            <!--<div class="toolbar-item"
                 :class="{active: isActivePage(PAGES.BOOKMARKS)}">
                <router-link class="link-ycc"
                             :to="{name: PAGES.BOOKMARKS}">
                    <div class="toolbar-item_icon">
                        <span class="icon-ycc">
                            <i class="material-icons">bookmark</i>
                        </span>
                    </div>

                    <span class="toolbar-item_title" v-translate>Bookmarks</span>
                </router-link>

                <div class="toolbar-item_menu"
                     :class="{hidden: isOpenAside}">
                    <router-link class="link-ycc"
                                 :to="{name: PAGES.BOOKMARKS}>
                        <div class="menu-item" v-translate>
                            Bookmarks
                        </div>
                    </router-link>
                </div>
            </div>-->
        </div>

        <ul v-if="tabs">
            <li class="toolbar-item"
                v-for="tab in tabs"
                :key="tab.key">
                <router-link class="link-ycc"
                             :class="{'disabled': tab.disabled}"
                             :to="{ name: tab.link, params: tab.params }">
                            <span class="toolbar-item_icon">
                                <span class="icon-ycc"
                                      v-if="tab.icon"><i class="material-icons">{{ tab.icon }}</i></span>
                            </span>

                    <span class="toolbar-item_title">{{ tab.label }}</span>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import PAGES from '../../constants/pages';

export default {
    name : 'YccToolbar',
    props: {
        page             : { type: String, default: PAGES.ALL_STREAMS },
        tabs             : { type: Object, default: null },
        isOpenAside      : Boolean,
        isReadOnlyProfile: Boolean,
    },
    data () {
        return {
            PAGES,
        };
    },
    methods: {
        isActivePage (name) {
            return name === this.page;
        },
    },
};
</script>
