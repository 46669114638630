const TYPES = {
    PERIOD: 'period',
    SINGLE: 'single',
    MULTI : 'multi',
};

const WEEKDAY_LABELS = {
    ru: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
    en: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
};

const MAX_MONTH_IN_LINE       = 3;
const MAX_MONTH_IN_DATEPICKER = 12;
const BORDER_WIDTH            = 1;
const MONTH_WIDTH             = 200;
const ISO_DATE_FORMAT         = 'YYYY-MM-DD';

const DEFAULT_STATE = {
    datepickerId    : 'datepicker',
    lang            : 'en',
    numberOfMonth   : 3,
    rangeType       : TYPES.SINGLE,
    significantDates: [],
    size            : '',

    start         : null,
    end           : null,
    maxAllowedDate: null,
    maxRange      : null,
    minAllowedDate: null,

    isHighlightToday  : true,
    isHighlightWeekend: true,
    isInline          : false,
    isDisabled        : false,
};

const TEXT_POSITIONS = {
    LEFT : 'left',
    RIGHT: 'right',
};

const TEXT_POSITION_CSS_CLASSES = {
    LEFT : 'text-align-left',
    RIGHT: 'text-align-right',
};

export {
    TYPES,
    WEEKDAY_LABELS,
    MAX_MONTH_IN_LINE,
    BORDER_WIDTH,
    MONTH_WIDTH,
    MAX_MONTH_IN_DATEPICKER,
    ISO_DATE_FORMAT,
    DEFAULT_STATE,
    TEXT_POSITIONS,
    TEXT_POSITION_CSS_CLASSES,
};
