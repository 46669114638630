import { GROUP_TYPES } from '@/constants/main';

export function getGroupedStreams (streams, groupingType) {
    const groupedStreams = {};

    function pushStream (groupName, stream) {
        if (!groupedStreams[groupName]) {
            groupedStreams[groupName] = {
                name   : groupName,
                streams: [],
            };
        }
        groupedStreams[groupName].streams.push(stream);
    }

    if (groupingType === GROUP_TYPES.TAG.key) {
        streams.forEach((stream) => {
            if (stream.tags && stream.tags.length > 0) {
                stream.tags.forEach((tag) => {
                    pushStream(tag, stream);
                });
            }
            else {
                pushStream('untagged', stream);
            }
        });
    }

    return groupedStreams;
}

export function saveToLocalStorage ({ key, value, page }) {
    const streamsPageSettings = JSON.parse(localStorage.getItem(page)) || {};

    streamsPageSettings[key] = value;
    localStorage.setItem(page, JSON.stringify(streamsPageSettings));
}
