/* eslint-disable import/no-cycle */
import axios      from 'axios';
import httpClient from '@/config/httpClient';
import {
    CLUSTER_NODES_URL,
    getClusterNodeDeleteUrl,
    getClusterNodeDrainUrl,
    getClusterNodeUrl,
    INTERNAL_NODES_URL,
}                 from '@/constants/api';

const { CancelToken } = axios;

export default {
    namespaced: true,
    state     : {
        clusterNodes          : null,
        isFetchingClusterNodes: false,
        isDrainingNode        : false,
        isDeletingNode        : false,

        fetchingNodesTimer: null,
        cancelNodesRequest: null,
    },
    mutations : {
        setClusterNodes (state, { nodes }) {
            state.clusterNodes = nodes;
        },
        setFetchingNodesTimer (state, { timer }) {
            state.fetchingNodesTimer = timer;
        },
        clearFetchingNodesTimer (state) {
            clearTimeout(state.fetchingNodesTimer);
        },
        setCancelNodesRequest (state, { cancel }) {
            state.cancelNodesRequest = cancel;
        },
    },
    actions   : {
        async fetchNodes ({ state, commit }, { isAdminProfile }) {
            if (state.cancelNodesRequest) {
                state.cancelNodesRequest('replaced');
            }

            state.isFetchingClusterNodes = true;

            await httpClient
                .get(isAdminProfile ? CLUSTER_NODES_URL : INTERNAL_NODES_URL, {
                    cancelToken: new CancelToken((cancel) => {
                        commit('setCancelNodesRequest', { cancel });
                    }),
                })
                .then((res) => {
                    commit('setClusterNodes', { nodes: res.data || null });
                })
                .finally(() => {
                    state.isFetchingClusterNodes = false;
                });
        },
        async updateNode ({ state, dispatch }, { id, name, isAdminProfile }) {
            state.isUpdatingNode = true;

            await httpClient
                .post(getClusterNodeUrl(id), { name })
                .then(() => {
                    dispatch('fetchNodes', { isAdminProfile });
                })
                .finally(() => {
                    state.isUpdatingNode = false;
                });
        },
        async drainNode ({ state, dispatch }, { id, isAdminProfile }) {
            state.isDrainingNode = true;

            await httpClient
                .post(getClusterNodeDrainUrl(id))
                .then(() => {
                    dispatch('fetchNodes', { isAdminProfile });
                })
                .finally(() => {
                    state.isDrainingNode = false;
                });
        },
        async deleteNode ({ state, dispatch }, { id, isAdminProfile }) {
            state.isDeletingNode = true;

            await httpClient
                .delete(getClusterNodeDeleteUrl(id))
                .then(() => {
                    dispatch('fetchNodes', { isAdminProfile });
                })
                .finally(() => {
                    state.isDeletingNode = false;
                });
        },
        runUpdatingNodes ({ state, commit, dispatch }, { isAdminProfile }) {
            state.isFetchingLogs = true;

            dispatch('fetchNodes', { isAdminProfile });

            let timer = setTimeout(function tick () {
                dispatch('fetchNodes', { isAdminProfile });

                timer = setTimeout(tick, 1000);
                commit('setFetchingNodesTimer', { timer });
            }, 1000);

            commit('setFetchingNodesTimer', { timer });
        },
        clearNodesTimer ({ commit, state }) {
            commit('clearFetchingNodesTimer');

            if (state.cancelNodesRequest) {
                state.cancelNodesRequest('replaced');
            }
        },
    },
};
