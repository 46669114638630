<template>
    <div class="alert-ycc"
         :class="alertCssClasses"
         role="alert">
        <div class="alert-ycc_icon"
             v-if="isShowIcon">
        </div>
        <div class="alert-ycc_container">
            <div class="alert-ycc_title"
                 v-if="hasTitle">
                {{ title }}
            </div>
            <div class="alert-ycc_content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import ALERT_CLASSES from './alertCssClasses';

export default {
    name    : 'YccAlert',
    props   : {
        name   : { type: String, default: 'attention' },
        title  : { type: String, default: '' },
        isFixed: Boolean,
    },
    computed: {
        alertCssClasses () {
            return [ALERT_CLASSES[this.name].name, { fixed: this.isFixed }];
        },
        hasTitle () {
            return (this.title !== '');
        },
        isShowIcon () {
            return ALERT_CLASSES[this.name].hasIcon;
        },
    },
};
</script>

<style lang="scss"
       scoped>
@import "./alert";
</style>
