import Vue            from 'vue';
import YccHintContent from './YccHintContent.vue';

// Создать глобальную шину, если она не создана
if (!window.bus) {
    window.bus = new Vue();
}

const { bus } = window;

const DEFAULT_STATE = {};

/* eslint-disable object-curly-newline */
export default function createPopup ({ hint, hintId, position, isDark, maxWidth }, id) {
    return new Vue({
        el        : `#${id}`,
        template  : `
            <ycc-hint-content :hint="hint"
                              :class="{open: isOpen}"
                              :hint-id="hintId"
                              :position="position"
                              :is-open="isOpen"
                              :is-dark="isDark"
                              :location="location"
                              :max-width="maxWidth">
            </ycc-hint-content>`,
        components: { YccHintContent },
        data      : {
            hint,
            hintId,
            position,
            isDark,
            maxWidth,
            isOpen    : false,
            location  : null,
            openerElem: null,
        },
        created () {
            this.location = {
                left  : 0,
                top   : 0,
                height: 0,
            };
            bus.$on('opened-hint', this.openPopup);
            bus.$on('closed-hint', this.closePopup);
        },
        methods   : {
            openPopup (payload) {
                this.openerElem = payload.openerElem;
                this.setLocation(payload.openerElem);
                this.setNewData(payload.props);
                this.open();
                window.addEventListener('resize', this.setLocation);
            },
            closePopup () {
                window.removeEventListener('resize', this.setLocation);
                this.close();
            },
            open () {
                this.isOpen = true;
            },
            close () {
                this.isOpen = false;
                this.clean();
            },
            clean () {
                this.setNewData(DEFAULT_STATE);
            },
            setNewData (data) {
                Object.keys(data).forEach((prop) => {
                    this[prop] = data[prop];
                });
            },
            setLocation () {
                const box = this.openerElem.getBoundingClientRect();

                this.location = {
                    left  : box.left + window.pageXOffset,
                    top   : box.top + window.pageYOffset,
                    width : box.width,
                    height: box.height,
                    y     : box.y,
                    x     : box.x,
                };
            },
        },
    });
}
