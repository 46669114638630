import dayjs          from 'dayjs';
import { formatDate } from '@/extends/lib';
import { EN }         from '@/constants/main';

const MS_IN_HOUR         = 60 * 60 * 1000;
const MS_IN_MINUTE       = 60 * 1000;
const MARKER_LABEL_WIDTH = 70;
const LABELS_MAP_KEYS    = {
    SIXTY   : 'sixty',
    TWELVE  : 'twelve',
    QUARTER : 'quarter',
    HALF    : 'half',
    FULL    : 'full',
    MULTIPLE: 'multiple',
};
const LABELS_MAP         = [
    { key: LABELS_MAP_KEYS.SIXTY, value: 60, minutes: 1 },
    { key: LABELS_MAP_KEYS.TWELVE, value: 12, minutes: 5 },
    { key: LABELS_MAP_KEYS.QUARTER, value: 4, minutes: 15 },
    { key: LABELS_MAP_KEYS.HALF, value: 2, minutes: 30 },
    { key: LABELS_MAP_KEYS.FULL, value: 1, minutes: 60 },
    { key: LABELS_MAP_KEYS.MULTIPLE },
];

const getMarkerOptions = ({ hoursNumber, possibleLabels, minutesNumber }) => {
    let options = { multiplicity: null, labelsNumber: 0 };

    LABELS_MAP.some((item) => {
        if (item.key === LABELS_MAP_KEYS.MULTIPLE) {
            options = {
                type        : item.key,
                multiplicity: Math.ceil(hoursNumber / possibleLabels),
                labelsNumber: Math.ceil(hoursNumber / Math.ceil(hoursNumber / possibleLabels)),
            };
            return true;
        }
        if (minutesNumber / item.minutes <= possibleLabels) {
            options = {
                type        : item.key,
                multiplicity: item.value,
                minutes     : item.minutes,
                labelsNumber: Math.ceil(minutesNumber / item.minutes),
            };
            return true;
        }
        return false;
    });

    return options;
};

const getLeftPosForMarker = (timestamp, period, secondsInPx) => {
    const seconds = (timestamp - period.start) / 1000;
    return seconds / secondsInPx;
};
const getMarker           = (
    period,
    timeMS,
    secondsInPx,
    isVisibleLabel,
    lang    = EN,
    isSmall = false,
) => {
    const label = dayjs(timeMS).hour() === 0 && dayjs(timeMS).minute() === 0
        ? formatDate(timeMS, 'MMM DD', lang)
        : formatDate(timeMS, 'HH:mm', lang);

    return {
        left    : `${getLeftPosForMarker(timeMS, period, secondsInPx)}px`,
        time    : timeMS,
        cssClass: isSmall ? 'small' : 'medium',
        type    : dayjs(timeMS).hour() !== 0 ? 'hour' : 'day',
        label   : isVisibleLabel ? label : ' ',
    };
};

const getFirstFullHourMS = (period) => {
    const year  = dayjs(period.start).year();
    const month = dayjs(period.start).month();
    const day   = dayjs(period.start).date();
    const hour  = dayjs(period.start).hour();

    return dayjs(new Date(year, month, day, hour)).valueOf();
};

const getFirstMarkerTime = (options, period) => {
    let firstMarkerTime = getFirstFullHourMS(period);

    const getFirstMarkerByTime = (minutes) => {
        const year       = dayjs(period.start).year();
        const month      = dayjs(period.start).month();
        const day        = dayjs(period.start).date();
        const hour       = dayjs(period.start).hour();
        let startMinutes = dayjs(period.start).minute();

        while (startMinutes % minutes !== 0) {
            startMinutes += 1;
        }

        return dayjs(new Date(year, month, day, hour, startMinutes)).valueOf();
    };

    if (options.type === LABELS_MAP_KEYS.SIXTY
        || options.type === LABELS_MAP_KEYS.TWELVE
        || options.type === LABELS_MAP_KEYS.QUARTER
        || options.type === LABELS_MAP_KEYS.HALF) {
        firstMarkerTime = getFirstMarkerByTime(options.minutes);
    }

    return firstMarkerTime;
};

const getNewMarkers = (options, period, secondsInPx, lang = EN) => {
    const newMarkers = [];
    let markerTime   = getFirstMarkerTime(options, period);

    for (let i = 0; i < options.labelsNumber; i++) {
        if (markerTime > period.start) {
            const marker = getMarker(
                period,
                markerTime,
                secondsInPx,
                lang,
                true,
            );
            newMarkers.push(marker);
        }
        if (options.type === LABELS_MAP_KEYS.MULTIPLE) {
            markerTime += (MS_IN_HOUR * options.multiplicity);
        }
        else {
            markerTime += (MS_IN_HOUR / options.multiplicity);
        }
    }
    return newMarkers;
};

export {
    MS_IN_HOUR,
    MS_IN_MINUTE,
    MARKER_LABEL_WIDTH,
    LABELS_MAP_KEYS,
    LABELS_MAP,

    getMarkerOptions,
    getNewMarkers,
};
