export default {
    color      : '#e6fc94',
    failedColor: '#874b4b',
    thickness  : '3px',
    transition : {
        speed      : '0.2s',
        opacity    : '0.6s',
        termination: 300,
    },
    autoRevert : true,
    location   : 'top',
    inverse    : false,
};
