// eslint-disable-next-line import/no-cycle
import httpClient from '@/config/httpClient';
import {
    ALL_MOSAICS_URL,
    MOSAIC_URL,
}                 from '@/constants/api';

export default {
    namespaced: true,
    state     : {
        mosaics             : [],
        currentMosaic       : null,
        isFetchingAllMosaics: false,
        isFetchingMosaic    : false,
        isDeleting          : false,

        fetchingMosaicTimer: null,
    },
    actions   : {
        async postMosaic (store, data) {
            let mosaic = null;

            await httpClient
                .post(MOSAIC_URL, data)
                .then((res) => {
                    mosaic = res.data;
                });

            return mosaic;
        },
        async saveMosaic (store, { id, data }) {
            let mosaic = null;

            await httpClient
                .post(`${MOSAIC_URL}/${id}`, data)
                .then((res) => {
                    mosaic = res.data;
                });

            return mosaic;
        },
        async fetchAllMosaics ({ commit, state }) {
            state.isFetchingAllMosaics = true;

            await httpClient
                .get(ALL_MOSAICS_URL)
                .then((res) => {
                    commit('setMosaics', { mosaics: res.data });
                })
                .finally(() => {
                    state.isFetchingAllMosaics = false;
                });
        },
        async fetchMosaic ({ state, commit }, { id, isPlay }) {
            state.isFetchingMosaic = true;
            let mosaicUrl          = `${MOSAIC_URL}/${id}`;

            if (isPlay) {
                mosaicUrl += '?extend_lease=true';
            }

            return httpClient
                .get(mosaicUrl)
                .then((res) => {
                    commit('setMosaic', { mosaic: res.data });
                    return res.data;
                })
                .finally(() => {
                    state.isFetchingMosaic = false;
                });
        },
        async deleteMosaic ({ state }, { id }) {
            state.ieDeleting = true;

            await httpClient
                .delete(`${MOSAIC_URL}/${id}`)
                .then()
                .finally(() => {
                    state.ieDeleting = false;
                });
        },
        runUpdatingMosaic ({ commit, dispatch }, { id, isPlay }) {
            dispatch('fetchMosaic', { id, isPlay });

            let timer = setTimeout(function tick () {
                dispatch('fetchMosaic', { id, isPlay });

                timer = setTimeout(tick, 10000);
                commit('setFetchingMosaicTimer', { timer });
            }, 10000);

            commit('setFetchingMosaicTimer', { timer });
        },
        clearTimers ({ commit, state }) {
            clearTimeout(state.fetchingMosaicTimer);
            commit('setFetchingMosaicTimer', { timer: null });
        },
        clearCurrentMosaic ({ commit }) {
            commit('setMosaic', { mosaic: null });
        },
    },
    mutations : {
        setMosaics (state, { mosaics }) {
            state.mosaics = mosaics;
        },
        setMosaic (state, { mosaic }) {
            state.currentMosaic = mosaic;
        },
        setFetchingMosaicTimer (state, { timer }) {
            state.fetchingMosaicTimer = timer;
        },
    },
};
