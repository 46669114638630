export default {
    ADD_STREAM        : 'add-stream',
    ADMIN             : 'admin',
    ADMIN_STATS       : 'admin-stats',
    ADMIN_CONFIG      : 'admin-config',
    ADMIN_USERS       : 'admin-users',
    ADMIN_CLUSTER     : 'admin-cluster',
    SERVER_DEBUG      : 'server-debug',
    ALL_STREAMS       : 'streams',
    AUTH              : 'auth',
    RECOVER_PASSWORD  : 'recover-password',
    BOOKMARKS         : 'bookmarks',
    HELP              : 'help',
    INIT              : 'init',
    NOT_FOUND         : 'not-found',
    PROFILE           : 'profile',
    PROFILE_INFO      : 'profile-info',
    PROFILE_EDIT      : 'profile-edit',
    PROFILE_TOKEN     : 'profile-token',
    PROFILE_AUTH_TOKEN: 'profile-auth-token',
    STREAM            : 'stream',
    STREAM_VIEW       : 'stream-view',
    STREAM_INFO       : 'stream-info',
    STREAM_SETTING    : 'stream-setting',
    STREAM_PERMISSION : 'stream-permission',
    STREAM_DEBUG      : 'stream-debug',
    SETTINGS          : 'settings',
    USER              : 'user',
    USER_ADD          : 'user-add',
    USER_INFO         : 'user-info',
    USER_EDIT         : 'user-edit',
    USER_PERMISSION   : 'user-permission',
    USER_AUTH_TOKEN   : 'user-auth-token',
    LICENSE           : 'license',
    CREATE_MOSAIC     : 'create-mosaic',
    WATCH_MOSAIC      : 'watch-mosaic',
    EDIT_MOSAIC       : 'edit-mosaic',
    ALL_MOSAICS       : 'mosaics',
    TUNNEL            : 'tunnel',
    AUDIT_LOGS        : 'audit-logs',
};
