import {
    RU,
    EN,
} from '@/constants/main';

export const BASE_LANDING_URL = 'https://yucca.app';
export const DOCS_LINK        = 'https://docs.yucca.app';

// eslint-disable-next-line import/prefer-default-export
export function getPricesLink (utm, lang = EN) {
    if (lang === RU) {
        return `${BASE_LANDING_URL}/ru/prices?utm_source=${utm}`;
    }
    return `${BASE_LANDING_URL}/en/prices?utm_source=${utm}`;
}
