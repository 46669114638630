<template>
    <div>
        <ycc-calendar-controls v-if="useControls"
                               :min-date="minDate"
                               :max-date="maxDate"
                               :months="months"
                               @decreased-month="decreaseMonth"
                               @increased-month="increaseMonth">
        </ycc-calendar-controls>

        <div class="calendar-ycc"
             :class="{'multi': isMultiRangeType}">
            <div class="months">
                <div class="month"
                     v-for="(month, key) of months"
                     :key="key">
                    <div class="month_header">
                        <div class="month_title">
                            {{ month.monthLabel }} {{ month.yearLabel }}
                        </div>
                    </div>

                    <div class="month_content">
                        <div class="week-labels">
                            <div class="week-labels_item"
                                 :class="highlightWeekday(key)"
                                 v-for="(weekdayLabel, key) of weekdayLabels"
                                 :key="key">
                                <div class="item-content">{{ weekdayLabel }}</div>
                            </div>
                        </div>

                        <div class="weeks">
                            <div :key="key"
                                 class="week"
                                 v-for="(week, key) of month.weeks">
                                <div class="week_item"
                                     ref="day"
                                     v-for="(day, key) of week"
                                     :class="getDayClasses(day)"
                                     :data-date="day.date"
                                     :key="key"
                                     @click.prevent="onClickDay(day.date)">
                                    <div class="item-content"
                                         :style="day.style"
                                         :title="day.title"
                                         :class="day.class">
                                        {{ day.dayNumber }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs               from 'dayjs';
import 'dayjs/locale/ru';
import YccCalendarControls from './YccCalendarControls.vue';
import {
    ISO_DATE_FORMAT,
    MAX_MONTH_IN_LINE,
    TYPES,
    WEEKDAY_LABELS,
}                          from './constants';
import PropertyError       from '../../extends/PropertyError';

function getDefaultStartDate () {
    const date = dayjs();
    return dayjs(new Date(date.year(), date.month(), 1)).format(ISO_DATE_FORMAT);
}

export default {
    name      : 'ycc-calendar',
    components: {
        YccCalendarControls,
    },
    props     : {
        calendarId          : {
            type: String,
        },
        // 'period' / 'single' / 'multi'
        rangeType           : {
            type   : String,
            default: TYPES.SINGLE,
        },
        // Выбранный период для 'period' / 'single'
        period              : {
            type   : Object,
            default: null,
        },
        // максимальный размер периода
        maxRange            : {
            type   : Number,
            default: null,
        },
        // Дата, с месяца этой даты начнется календарь
        startDate           : {
            type: String,
            default () {
                return getDefaultStartDate();
            },
        },
        // Количество отображаемых месяцев
        numberOfMonth       : {
            type   : Number,
            default: MAX_MONTH_IN_LINE,
        },
        // Минимально допустимая дата (предел слева)
        minAllowedDate      : {
            type   : [String, Boolean],
            default: null,
        },
        // Максимально допустимая дата (предел справа)
        maxAllowedDate      : {
            type   : [String, Boolean],
            default: null,
        },
        // Массив значимых дат (помеченных)
        significantDates    : {
            type: Array,
            default () {
                return [];
            },
        },
        // Флаг подсветки Сегодня
        isHighlightToday    : {
            type   : Boolean,
            default: true,
        },
        // Флаг подсветки выходных
        isHighlightWeekend  : {
            type   : Boolean,
            default: true,
        },
        useControls         : {
            type: Boolean,
        },
        // Язык
        lang                : {
            type   : String,
            default: 'en',
        },
        // Список периодов для 'multi'
        customPeriods       : {
            type: Array,
            default () {
                return [];
            },
        },
        notSelectedDates    : {
            type: Array,
            default () {
                return [];
            },
        },
        isSelectingOnePeriod: {
            type   : Boolean,
            default: false,
        },
        isDisabledDate      : {
            type   : Function,
            default: null,
        },
    },
    data () {
        return {
            months: [],

            /* Процесс выделения */
            isSelecting   : false,
            selectedPeriod: {
                underCursor: null,
                start      : null,
                end        : null,
            },

            // multi / customPeriod -> [{start: 'yyyy-mm-dd', end: 'yyyy-mm-dd'}, ...]
            selectingCustomPeriod: {
                underCursor: null,
                start      : null,
                end        : null,
            },
            selectingDates       : [],
        };
    },
    created () {
        dayjs.locale(this.lang);
        this.checkPropsIsCorrect();
        this.getMonths(dayjs(this.startDate));
        this.setSelectedPeriod({
            start      : this.period ? this.period.start : null,
            end        : this.period ? this.period.end : null,
            underCursor: null,
        });
    },
    watch     : {
        startDate (newDate) {
            this.getMonths(dayjs(newDate));
        },
        isSelecting (newStep) {
            if (newStep) {
                this.addMouseEnterToDayListener();
            }
            else {
                this.removeMouseEnterToDayListener();
            }
        },
        period (newPeriod) {
            this.setSelectedPeriod({
                start      : newPeriod.start,
                end        : newPeriod.end,
                underCursor: null,
            });
        },
        numberOfMonth () {
            this.refresh();
        },
        lang (newLang) {
            dayjs.locale(newLang);
            this.refresh();
        },
        customPeriods: {
            handler () {
                this.refresh();
            },
            deep: true,
        },
    },
    computed  : {
        weekdayLabels () {
            return WEEKDAY_LABELS[this.lang];
        },
        minDate () {
            return this.getExtremumDate(this.minAllowedDate, this.getSettingsMinDate);
        },
        maxDate () {
            return this.getExtremumDate(this.maxAllowedDate, this.getSettingsMaxDate);
        },
        isMultiRangeType () {
            return this.rangeType === TYPES.MULTI;
        },
        allPeriodProperties () {
            const allPeriodProperties = {};
            let periodStyle;

            this.customPeriods.forEach((period) => {
                period.dates.forEach((periodDate) => {
                    if (period.style) {
                        periodStyle = { ...period.style };
                        if (period.style.backgroundColor) {
                            periodStyle.backgroundColor = period.style.backgroundColor;
                        }
                    }

                    allPeriodProperties[periodDate] = {
                        style: periodStyle || '',
                        title: period.name || '',
                        class: allPeriodProperties[periodDate]
                            ? `${allPeriodProperties[periodDate].class} ${period.class}`
                            : period.class,
                    };
                });
            });

            return allPeriodProperties;
        },
    },
    methods   : {
        getMonths (start) {
            const endDate = start.add(this.numberOfMonth - 1, 'month');
            let startDate = dayjs(start);
            this.months   = [];

            for (; startDate <= endDate; startDate = startDate.add(1, 'day')) {
                const yearLabel           = startDate.format('YYYY');
                const monthLabel          = startDate.format('MMMM');
                const monthNumber         = startDate.month();
                const daysInMonth         = startDate.daysInMonth();
                const firstWeekdayInMonth = startDate.day();

                const weeksAndDays = this.getWeeksWithDays(
                    firstWeekdayInMonth,
                    daysInMonth,
                    monthNumber,
                    yearLabel,
                );

                let month = this.months[this.months.length - 1];

                if (!month || month.monthLabel !== monthLabel) {
                    this.months.push(month = {
                        yearLabel,
                        monthLabel,
                        monthNumber,
                        daysInMonth,
                        firstWeekdayInMonth,
                        weeks: weeksAndDays.weeks,
                        days : weeksAndDays.days,
                    });
                }
            }
        },
        getWeeksWithDays (firstWeekdayInMonth, daysInMonth, monthNumber, yearLabel) {
            const { dayIndexStart, dayIndexStop, correctedFirstWeekday } = (
                this.correctLocaleWeekdays(firstWeekdayInMonth)
            );

            const weeks = [];
            const days  = [];

            let isMonthStarted = false;
            let isMonthEnded   = false;
            let monthDay       = 0;

            for (let weekIndex = 1; weekIndex <= 6 && !isMonthEnded; weekIndex++) {
                const week = [];

                for (let dayIndex = dayIndexStart; dayIndex <= dayIndexStop; dayIndex++) {
                    if (!isMonthStarted && dayIndex >= correctedFirstWeekday) {
                        monthDay       = 1;
                        isMonthStarted = true;
                        days.push(monthDay);
                    }
                    else if (isMonthStarted && !isMonthEnded) {
                        monthDay += 1;
                        days.push(monthDay);
                    }

                    const date = monthDay
                        ? dayjs(new Date(yearLabel, monthNumber, monthDay)).format(ISO_DATE_FORMAT)
                        : null;

                    const dateProperties = this.allPeriodProperties[date]
                        ? this.allPeriodProperties[date]
                        : { style: null, title: '' };

                    week.push({
                        date,
                        weekDay  : dayIndex,
                        dayNumber: monthDay || '',
                        ...dateProperties,
                    });
                    if (isMonthStarted && !isMonthEnded && monthDay >= daysInMonth) {
                        monthDay     = 0;
                        isMonthEnded = true;
                    }
                }
                weeks.push(week);
            }

            return { weeks, days };
        },
        correctLocaleWeekdays (firstWeekdayInMonth) {
            return {
                dayIndexStart        : 1,
                dayIndexStop         : 7,
                correctedFirstWeekday: firstWeekdayInMonth === 0 ? 7 : firstWeekdayInMonth,
            };
        },
        highlightWeekday (dayIndex) {
            let dayClass = '';

            if (this.isHighlightWeekend) {
                if (dayIndex === 5 || dayIndex === 6) {
                    dayClass = 'weekend';
                }
            }
            return dayClass;
        },
        checkPropsIsCorrect () {
            if (dayjs(this.minDate) > dayjs(this.maxDate)) {
                throw new PropertyError(
                    'minAllowedDate',
                    `Минимально разрешенная дата больше максимально разрешенной даты в календаре '${this.calendarId}'`,
                );
            }
            if (this.period) {
                if (dayjs(this.period.start) > dayjs(this.period.end)) {
                    throw new PropertyError(
                        'period',
                        `Начало периода больше конца периода в календаре '${this.calendarId}'`,
                    );
                }
                if (dayjs(this.period.start) < dayjs(this.minDate)) {
                    throw new PropertyError(
                        'period.start',
                        `Часть периода за пределами минимально допустимой даты календаря '${this.calendarId}'`,
                    );
                }
                if (dayjs(this.period.end) > dayjs(this.maxDate)) {
                    throw new PropertyError(
                        'period.end',
                        `Часть периода за пределами максимально доступной зоне календаря '${this.calendarId}'`,
                    );
                }
            }
        },

        /** Extremum */
        getExtremumDate (allowedDate, getter) {
            let minDate;
            if (allowedDate === true || allowedDate === 'true') {
                minDate = dayjs(new Date()).format(ISO_DATE_FORMAT);
            }
            else {
                minDate = allowedDate || null;
            }

            if (this.isSelecting) {
                return getter(minDate);
            }
            return minDate;
        },
        /**
         * Получить минимально-допустимую дату с учетом максимального промижутка
         * @returns {*}
         */
        getSettingsMinDate (minDate) {
            const reportDate = this.selectedPeriod.start || this.selectedPeriod.end;

            if (reportDate) {
                const dateWithRange = dayjs(reportDate).subtract(this.maxRange, 'day');

                if ((dateWithRange < dayjs(minDate) || this.maxRange === null) && minDate) {
                    return minDate;
                }
                if (this.maxRange) {
                    return dateWithRange.format(ISO_DATE_FORMAT);
                }
            }
            if (minDate) {
                return minDate;
            }
            return null;
        },
        /**
         * Получить максимально-допустимую дату с учетом максимального промижутка
         * @returns {*}
         */
        getSettingsMaxDate (maxDate) {
            const reportDate = this.selectedPeriod.start || this.selectedPeriod.end;

            if (reportDate) {
                const dateWithRange = dayjs(reportDate).add(this.maxRange, 'day');

                if ((dateWithRange > new Date(maxDate) || this.maxRange === null) && maxDate) {
                    return maxDate;
                }
                if (this.maxRange) {
                    return dateWithRange.format(ISO_DATE_FORMAT);
                }
            }
            if (maxDate) {
                return maxDate;
            }
            return null;
        },

        /** Флаги для даты */
        hasDay (day) {
            return day.dayNumber !== '';
        },
        isWeekend (day) {
            return day.weekDay === 0 || day.weekDay === 6 || day.weekDay === 7;
        },
        isToday (day) {
            return dayjs(day.date).isSame(dayjs(), 'date');
        },
        isIncludedInSelectedPeriod (date) {
            const start = this.selectedPeriod.start ? this.selectedPeriod.start : this.selectedPeriod.underCursor;
            const end   = this.isSelecting && this.selectedPeriod.start
                ? this.selectedPeriod.underCursor : this.selectedPeriod.end;

            return dayjs(date) >= dayjs(start) && dayjs(date) <= dayjs(end);
        },
        isStart (date) {
            return date === this.selectedPeriod.start;
        },
        isEnd (date) {
            return date === this.selectedPeriod.end;
        },
        isDisableDate (date) {
            return dayjs(`${date}T00:00:00Z`).isBefore(dayjs(this.minDate)) || dayjs(date).isAfter(dayjs(this.maxDate));
        },
        isIncludedInSignificantDates (date) {
            return this.significantDates.some((significantDate) => dayjs(significantDate).isSame(date, 'day'));
        },
        getDayClasses (day) {
            return {
                day              : this.hasDay(day),
                weekend          : this.isWeekend(day) && this.isHighlightWeekend,
                today            : this.isToday(day) && this.isHighlightToday,
                selected         : this.isIncludedInSelectedPeriod(day.date),
                'selected-start' : this.isStart(day.date),
                'selected-end'   : this.isEnd(day.date),
                'day-disabled'   : this.isDisableDate(day.date)
                    || (this.isDisabledDate && this.isDisabledDate(day.date)),
                'day-significant': this.isIncludedInSignificantDates(day.date),
                'day-selected'   : this.isIncludedInSelectedPeriods(day.date)
                    && !this.isIncludedInNotSelectedDates(day.date),
            };
        },
        setSelectedPeriod (period) {
            this.selectedPeriod = { ...period };
        },
        refresh () {
            this.getMonths(dayjs(this.startDate));
        },

        /** Обработчики событий */
        // Обработчик клика по дню в календаре
        onClickDay (date) {
            if (date === null || this.isDisableDate(date) || (this.isDisabledDate && this.isDisabledDate(date))) {
                return;
            }

            let period = {};

            if (this.rangeType === TYPES.SINGLE) {
                period = this.onClickDaySingleHandler(date);
            }

            if (this.rangeType === TYPES.PERIOD) {
                period           = this.onClickDayPeriodHandler(date);
                this.isSelecting = !this.isSelecting;
            }

            if (this.rangeType === TYPES.MULTI) {
                if (this.selectingDates.includes(dayjs(date).format(ISO_DATE_FORMAT))
                    && (this.selectedPeriod.underCursor === null || Object.keys(this.selectedPeriod).length === 0)
                    && !this.isSelectingOnePeriod) {
                    this.isSelecting = false;
                    this.deleteDateFromSelected(date);
                }
                else {
                    period           = this.onClickDayMultiHandler(date);
                    this.isSelecting = !this.isSelecting;
                }
            }

            this.selectedPeriod = { ...period };

            this.$emit('updated-dates', {
                isSelecting: this.isSelecting,
                period     : this.selectedPeriod,
            });
        },
        onMouseEnterToDay (event) {
            const underCursor  = event.target.getAttribute('data-date');
            let { start, end } = this.selectedPeriod;

            if (dayjs(underCursor) < dayjs(this.selectedPeriod.start)) {
                end   = this.selectedPeriod.start;
                start = null;
            }
            else if (dayjs(underCursor) > dayjs(this.selectedPeriod.end)) {
                start = this.selectedPeriod.end;
                end   = null;
            }

            this.setSelectedPeriod({
                underCursor,
                start,
                end,
            });
        },
        addMouseEnterToDayListener () {
            this.$refs.day.forEach((elem) => {
                elem.addEventListener('mouseenter', this.onMouseEnterToDay);
            });
        },
        removeMouseEnterToDayListener () {
            this.$refs.day.forEach((elem) => {
                elem.removeEventListener('mouseenter', this.onMouseEnterToDay);
            });
        },

        decreaseMonth () {
            this.$emit('decreased-month');
        },
        increaseMonth () {
            this.$emit('increased-month');
        },

        onClickDaySingleHandler (date) {
            return {
                underCursor: date,
                start      : date,
                end        : date,
            };
        },
        onClickDayPeriodHandler (date) {
            let { start, end, underCursor } = this.selectedPeriod;

            if (this.isSelecting) {
                underCursor = null;
                start       = start || date;
                end         = end || date;
            }
            else {
                start       = date;
                end         = date;
                underCursor = date;
            }

            return { start, end, underCursor };
        },
        onClickDayMultiHandler (date) {
            const period  = this.onClickDayPeriodHandler(date);
            const endDate = dayjs(period.end);
            let startDate = dayjs(period.start);

            if (this.isSelecting) {
                for (; startDate <= endDate; startDate = startDate.add(1, 'day')) {
                    if (!this.selectingDates.includes(dayjs(startDate).format(ISO_DATE_FORMAT))) {
                        if (!this.isIncludedInNotSelectedDates(startDate)) {
                            this.selectingDates.push(dayjs(startDate).format(ISO_DATE_FORMAT));
                        }
                    }
                }
            }
            else if (this.isSelectingOnePeriod) {
                this.selectingDates = [];
            }

            /* eslint-disable-next-line */
            this.selectingDates.sort((a, b) => dayjs(a) > dayjs(b) ? 1 : -1);

            this.$emit('updated-multi-dates', {
                isSelecting: this.isSelecting,
                periods    : this.selectingDates,
            });

            return period;
        },

        /** Всё для 'multi' */
        isIncludedInSelectedPeriods (date) {
            return this.isIncludedInSelectedPeriod(date) || this.selectingDates.includes(date);
        },
        cleanSelected () {
            this.selectingDates = [];
            this.selectedPeriod = {
                start      : null,
                end        : null,
                underCursor: null,
            };
        },
        isIncludedInNotSelectedDates (date) {
            const currentDate   = new Date(date);
            const numberWeekday = currentDate.getDay();
            return this.notSelectedDates.includes(numberWeekday);
        },
        deleteDateFromSelected (date) {
            for (let i = 0; i < this.selectingDates.length; i++) {
                if (this.selectingDates[i] === date) {
                    this.selectingDates.splice(i, 1);
                }
            }
        },
    },
};
</script>

<style lang="scss">
    @import "scss/calendar";
</style>
