import axios      from 'axios';
// eslint-disable-next-line import/no-cycle
import httpClient from '@/config/httpClient';
import {
    TUNNEL_START_URL,
    TUNNEL_STATUS_URL,
    TUNNEL_STOP_URL,
}                 from '@/constants/api';
import STATUSES   from '../../components/tunnel/statuses';

const { CancelToken } = axios;

export default {
    namespaced: true,
    state     : {
        address            : '',
        fetchingStatusTimer: null,
        cancelStatusRequest: null,
        status             : STATUSES.disconnected,
        isConnected        : false,
        isStoppingTunnel   : false,
        isStartingTunnel   : false,
        isOpenSettings     : false,
        error              : null,
    },
    mutations : {
        setConnectedStatus (state, { isConnected }) {
            state.isConnected = isConnected;
        },
        setStatus (state, { status }) {
            state.status = status;
        },
        setAddress (state, { address }) {
            state.address = address;
        },
        setFetchingStatusTimer (state, { timer }) {
            state.fetchingStatusTimer = timer;
        },
        setCancelStatusRequest (state, { cancel }) {
            state.cancelStatusRequest = cancel;
        },
        setOpenSettingsState (state, { isOpenSettings }) {
            state.isOpenSettings = isOpenSettings;
        },
        clearFetchingStatusTimer (state) {
            clearTimeout(state.fetchingStatusTimer);
        },
        setTunnelError (state, { error }) {
            state.error = error;
        },
    },
    actions   : {
        async startTunnel ({ state, dispatch }, { location }) {
            if (state.cancelStatusRequest) {
                state.cancelStatusRequest('replaced');
            }

            state.isStartingTunnel = true;
            await httpClient
                .post(TUNNEL_START_URL, { location })
                .then(() => {
                    dispatch('runFetchingTunnelStatus');
                })
                .catch()
                .finally();
        },
        async stopTunnel ({ state, commit }) {
            state.isStoppingTunnel = true;
            await httpClient
                .post(TUNNEL_STOP_URL)
                .then(() => {
                    commit('setAddress', { address: '' });
                })
                .catch()
                .finally();
        },
        fetchTunnelStatus ({ state, commit }) {
            if (state.cancelStatusRequest) {
                state.cancelStatusRequest('replaced');
            }

            return httpClient
                .get(TUNNEL_STATUS_URL, {
                    cancelToken: new CancelToken((cancel) => {
                        commit('setCancelStatusRequest', { cancel });
                    }),
                })
                .then((res) => {
                    commit('setConnectedStatus', { isConnected: res.data.status === 'connected' });
                    commit('setStatus', { status: res.data.status });
                    commit('setAddress', { address: res.data.address });

                    if (res.data.error) {
                        commit('setTunnelError', { error: res.data.error });
                    }
                    else {
                        commit('setTunnelError', { error: null });
                    }
                    return res.data.status;
                })
                .catch()
                .finally(() => {
                    state.isStartingTunnel = false;
                    state.isStoppingTunnel = false;
                });
        },

        runFetchingTunnelStatus ({ commit, dispatch }) {
            dispatch('clearFetchingStatusTimer');
            dispatch('fetchTunnelStatus');

            let timer = setTimeout(function tick () {
                dispatch('fetchTunnelStatus')
                    .then((status) => {
                        if (status !== STATUSES.disconnected) {
                            timer = setTimeout(tick, 1000);
                            commit('setFetchingStatusTimer', { timer });
                        }
                    });
            }, 1000);

            commit('setFetchingStatusTimer', { timer });
        },
        clearFetchingStatusTimer ({ commit, state }) {
            commit('clearFetchingStatusTimer');

            if (state.cancelStatusRequest) {
                state.cancelStatusRequest('replaced');
            }
        },

        toggleSettings ({ state, commit }) {
            commit('setOpenSettingsState', { isOpenSettings: !state.isOpenSettings });
        },
        closeSettings ({ commit }) {
            commit('setOpenSettingsState', { isOpenSettings: false });
        },
    },
};
