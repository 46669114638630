import dayjs                from 'dayjs';
import YccDatepickerContent from './YccDatepickerContent.vue';
import {
    DEFAULT_STATE,
    ISO_DATE_FORMAT,
    MAX_MONTH_IN_LINE,
    TYPES,
}                           from './constants';

const defaultProps = {
    datepickerId      : 'calendar',
    rangeType         : TYPES.SINGLE,
    lang              : 'en',
    maxRange          : null,
    start             : null,
    end               : null,
    numberOfMonth     : MAX_MONTH_IN_LINE,
    size              : '',
    // Минимально допустимая дата (предел слева)
    minAllowedDate    : null,
    // Максимально допустимая дата (предел справа)
    maxAllowedDate    : null,
    // Массив значимых дат (помеченных)
    significantDates  : [],
    isHighlightToday  : true,
    isHighlightWeekend: false,
    isInline          : false,
    isDisabled        : false,
    isFixed           : false,
    isDisabledDate    : null,
};

export default {
    template  : `
        <div>
            <div :class="{'datepicker-background-ycc': isOpen}"></div>

            <ycc-datepicker-content
                    class="datepicker-bus"
                    :class="{open: isOpen, fixed: isFixed}"
                    :datepicker-id="datepickerId"
                    :range-type="rangeType"
                    :lang="lang"
                    :max-range="maxRange"
                    :start="start"
                    :end="end"
                    :number-of-month="numberOfMonth"
                    :size="size"
                    :min-allowed-date="minAllowedDate"
                    :max-allowed-date="maxAllowedDate"
                    :significant-dates="significantDates"
                    :is-highlight-today="isHighlightToday"
                    :is-highlight-weekend="isHighlightWeekend"
                    :is-inline="isInline"
                    :is-selecting="isSelecting"
                    :location="location"
                    :is-open="isOpen"
                    :is-disabled-date="isDisabledDate"
                    @updated-dates="onClickDay"
                    @changed-month-year="onChangeMonthYear">
            </ycc-datepicker-content>
        </div>`,
    components: { YccDatepickerContent },
    data () {
        return {
            isOpen     : false,
            isSelecting: false,
            isFixed    : false,
            period     : {},
            location   : {
                left  : 0,
                top   : 0,
                height: 0,
            },
            // Сохраним данные
            ...defaultProps,
        };
    },
    created () {
        // Слушаем события от инпута
        window.bus.$on('opened-datepicker', this.openDatepicker);
        window.bus.$on('closed-datepicker', this.close);
        window.bus.$on('set-fixing', this.setFixing);
        window.bus.$on('set-start', this.setStart);
        window.bus.$on('set-end', this.setEnd);
    },
    watch     : {
        isOpen (newState) {
            if (newState) {
                window.addEventListener('click', this.closeDatepickerOutside);
            }
            else {
                window.removeEventListener('click', this.closeDatepickerOutside);
            }
        },
    },
    methods   : {
        onClickDay ({ isSelecting, period }) {
            this.setSelectingState(isSelecting);
            this.setPeriod(period);

            window.bus.$emit('updated-dates', { isSelecting, period, datepickerId: this.datepickerId });

            // Если закончили выбор, то закрыть
            if (!isSelecting) {
                this.close();
            }
        },
        openDatepicker (payload) {
            this.setFixing(payload.isFixed);
            this.setLocation(payload.inputElem);
            this.setNewData(payload.props);
            this.open();
        },
        closeDatepicker () {
            if (this.isSelecting) {
                this.setSelectingState(false);

                window.bus.$emit('updated-dates', {
                    isSelecting : this.isSelecting,
                    period      : this.getCorrectPeriod(),
                    datepickerId: this.datepickerId,
                });
            }
            this.close();
        },
        closeDatepickerOutside (event) {
            if (!this.isChildElem(event.target)) {
                this.closeDatepicker();
            }
        },
        onChangeMonthYear (payload) {
            window.bus.$emit('changed-month-year', payload);
        },
        setFixing (state) {
            this.isFixed = state;
        },
        // проверяет является ли элемент потомком класса
        isChildElem (elem) {
            const elemChildren   = document.querySelectorAll('.datepicker-bus *');
            const openerChildren = document.querySelectorAll('.datepicker-ycc_opener *');

            for (let i = 0; i < elemChildren.length; i++) {
                if (elem === elemChildren[i]) {
                    return true;
                }
            }

            for (let i = 0; i < openerChildren.length; i++) {
                if (elem === openerChildren[i]) {
                    return true;
                }
            }

            return false;
        },
        clean () {
            this.setNewData(DEFAULT_STATE);
        },
        open () {
            this.isOpen = true;
        },
        close () {
            this.isOpen = false;
            this.clean();
            window.bus.$emit('close');
        },
        getCorrectPeriod () {
            const start = this.period.start
                ? this.period.start
                : this.period.end;
            const end   = this.period.end && this.period.end !== this.period.start
                ? this.period.start
                : dayjs(this.period.end).add(1, 'day').format(ISO_DATE_FORMAT);

            return { start, end, underCursor: null };
        },
        setLocation (inputElem) {
            const box = inputElem.getBoundingClientRect();

            this.location = {
                left  : this.isFixed ? box.left : box.left + window.pageXOffset,
                top   : this.isFixed ? box.top : box.top + window.pageYOffset,
                height: box.height,
            };
        },
        setNewData (data) {
            Object.keys(data).forEach((prop) => {
                this[prop] = data[prop];
            });
        },
        setPeriod (period) {
            this.period = { ...period };
        },
        setStart (start) {
            this.start = start;
        },
        setEnd (end) {
            this.end = end;
        },
        setSelectingState (state) {
            this.isSelecting = state;
        },
    },
};
