<template>
    <div class="calendar-controls">
        <span class="material-icons calendar-control_item calendar-control_item-left"
              :class="{'control-disabled': isDisablePrevMonthBtn}"
              aria-hidden="true"
              @click="decreaseMonth">
            keyboard_arrow_left
        </span>
        <span class="material-icons calendar-control_item calendar-control_item-right"
              :class="{'control-disabled': isDisableNextMonthBtn}"
              aria-hidden="true"
              @click="increaseMonth">
            keyboard_arrow_right
        </span>
    </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
    name    : 'ycc-calendar-controls',
    props   : {
        minDate: {
            type: [String, Date],
        },
        maxDate: {
            type: [String, Date],
        },
        months : {
            type: Array,
            default () {
                return [];
            },
        },
    },
    computed: {
        isDisableNextMonthBtn () {
            const month = this.months[this.months.length - 1];
            return dayjs(new Date(month.yearLabel, month.monthNumber + 1, 1)) > dayjs(this.maxDate)
                && this.maxDate;
        },
        isDisablePrevMonthBtn () {
            const date = dayjs(new Date(this.months[0].yearLabel, this.months[0].monthNumber, 1)).subtract(1, 'day');
            return date < dayjs(this.minDate)
                && this.minDate;
        },
    },
    methods : {
        decreaseMonth () {
            if (this.isDisablePrevMonthBtn) {
                return;
            }
            this.$emit('decreased-month');
        },
        increaseMonth () {
            if (this.isDisableNextMonthBtn) {
                return;
            }
            this.$emit('increased-month');
        },
    },
};
</script>

<style lang="scss">
    @import "scss/calendar-controls";
</style>
