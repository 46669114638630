<template>
    <div class="hint-ycc popover-ycc-container"
         :class="popoverYccCssClasses"
         :style="hintYccInlineStyle">
        <div class="popover-ycc"
             ref="popover">
            <div class="popover-ycc_content"
                 v-html="hint">
            </div>
        </div>
    </div>
</template>

<script>
import CorrectingPosition from '../../extends/correctingPosition';

export default {
    name : 'ycc-hint-content',
    props: {
        hintId  : {
            type   : [String, Number],
            default: 'hint',
        },
        hint    : String,
        position: {
            type   : String,
            default: 'top',
        },
        location: {
            type: Object,
            default () {
                return {};
            },
        },
        isDark  : Boolean,
        isOpen  : {
            type   : Boolean,
            default: false,
        },
        maxWidth: {
            type   : [String, Number],
            default: 250,
        },
    },
    data () {
        return {
            positionProperties: {},
            contentWidth      : null,
        };
    },
    watch: {
        isOpen (newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.changePositionProperties();
                });
            }
        },
    },
    created () {
        this.positionProperties = {
            class: 'top',
            style: {},
        };
    },
    computed: {
        popoverYccCssClasses () {
            return [
                this.positionProperties.class,
                {
                    'open-hint': this.isOpen,
                    'dark-hint': this.isDark,
                },
            ];
        },
        hintYccInlineStyle () {
            return [
                this.positionProperties.style,
                {
                    'max-width': Number(this.maxWidth) ? `${this.maxWidth}px` : this.maxWidth,
                },
            ];
        },
    },
    methods : {
        changePositionProperties () {
            if (this.$refs.popover) {
                const contentCoords = this.$refs.popover.getBoundingClientRect();

                if (!this.contentWidth) {
                    this.contentWidth = contentCoords.width;
                }

                const correctingPosition         = new CorrectingPosition({
                    position    : this.position,
                    contentWidth: this.contentWidth,
                });
                const positionPropertiesCssClass = correctingPosition.getPositionCssClass({
                    contentCoords,
                    btnCoords: this.location,
                    position : this.position,
                });

                this.positionProperties = {
                    class: positionPropertiesCssClass,
                    style: correctingPosition.getStyle({
                        contentCoords,
                        btnCoords: this.location,
                        position : positionPropertiesCssClass,
                    }),
                };
            }
        },
    },
};
</script>

<style lang="scss"
       scoped>
@use "../../scss/abstracts/colours";

.hint-ycc {
    display  : none;
    position : absolute;
    top      : 0;
    z-index  : 9999;

    &.open {
        display : block;
    }

    .popover-ycc {
        width : auto;
    }
}

.popover-ycc-container.dark-hint .popover-ycc {
    background-color : colours.$coal;
    color            : colours.$snow;
    padding          : 5px 10px 6px;
    border           : 0;
    box-shadow       : none;

    &:before {
        background-color : colours.$coal;
        border           : 0;
        width            : 10px;
        height           : 10px;
    }

    &:after {
        width  : 0;
        height : 0;
    }
}

.popover-ycc-container {
    &.open-hint {
        opacity   : 1;
        animation : fade-hint 300ms cubic-bezier(0, 0, 0.4, 1);
    }
}

@keyframes fade-hint {
    from {
        opacity : 0;
    }
    50% {
        opacity : 0;
    }
    to {
        opacity : 1;
    }
}
</style>
