import { getScrollWidth } from '@/extends/lib';
import PAGES              from './pages';

export const VIEW_MODES_KEYS = {
    GRID : 'GRID',
    TABLE: 'TABLE',
};

export const SORT_TYPES_KEYS = {
    DATE    : 'DATE',
    NAME    : 'NAME',
    LOCATION: 'LOCATION',
};

export const GROUP_TYPES_KEYS = {
    TAG     : 'TAG',
    LOCATION: 'LOCATION',
    NO_GROUP: 'NO_GROUP',
};

export const ADMIN_TABS_KEYS = {
    STATS       : PAGES.ADMIN_STATS,
    USERS       : PAGES.ADMIN_USERS,
    CONFIG      : PAGES.ADMIN_CONFIG,
    LICENSE     : PAGES.LICENSE,
    SERVER_DEBUG: PAGES.SERVER_DEBUG,
    TUNNEL      : PAGES.TUNNEL,
    CLUSTER     : PAGES.ADMIN_CLUSTER,
    AUDIT_LOGS  : PAGES.AUDIT_LOGS,
};

export const STREAM_INFO_TABS_KEYS = {
    INFO        : PAGES.STREAM_INFO,
    PERMISSION  : PAGES.STREAM_PERMISSION,
    SETTINGS    : PAGES.STREAM_SETTING,
    STREAM_DEBUG: PAGES.STREAM_DEBUG,
};

export const USER_TABS_KEYS = {
    INFO      : PAGES.USER_INFO,
    EDIT      : PAGES.USER_EDIT,
    PERMISSION: PAGES.USER_PERMISSION,
    AUTH_TOKEN: PAGES.USER_AUTH_TOKEN,
};

export const PROFILE_TABS_KEYS = {
    INFO      : PAGES.PROFILE_INFO,
    EDIT      : PAGES.PROFILE_EDIT,
    TOKEN     : PAGES.PROFILE_TOKEN,
    AUTH_TOKEN: PAGES.PROFILE_AUTH_TOKEN,
};

export const USER_PERMISSION_TYPES = {
    ADMIN: 'ADMIN',
    USER : 'USER',
};

export const STREAM_PERMISSION_TYPES = {
    ADMIN: 'admin',
    EDIT : 'edit',
    VIEW : 'view',
    NONE : 'none',
};

export const UNLIMITED = 'UNLIMITED';

export const CLUSTER_NODE_STATE = {
    alive  : 'alive',
    suspect: 'suspect',
    dead   : 'dead',
    left   : 'left',
    unknown: 'unknown',
};

export const ADMIN_TABS = {
    [ADMIN_TABS_KEYS.STATS]       : {
        key  : ADMIN_TABS_KEYS.STATS,
        icon : 'show_chart',
        label: '',
    },
    [ADMIN_TABS_KEYS.USERS]       : {
        key  : ADMIN_TABS_KEYS.USERS,
        icon : 'supervisor_account',
        label: '',
    },
    [ADMIN_TABS_KEYS.CONFIG]      : {
        key  : ADMIN_TABS_KEYS.CONFIG,
        icon : 'settings',
        label: '',
    },
    [ADMIN_TABS_KEYS.SERVER_DEBUG]: {
        key  : ADMIN_TABS_KEYS.SERVER_DEBUG,
        icon : 'bug_report',
        label: '',
    },
    [ADMIN_TABS_KEYS.LICENSE]     : {
        key  : ADMIN_TABS_KEYS.LICENSE,
        icon : 'card_membership',
        label: '',
    },
    [ADMIN_TABS_KEYS.TUNNEL]      : {
        key  : ADMIN_TABS_KEYS.TUNNEL,
        icon : 'public',
        label: '',
    },
    [ADMIN_TABS_KEYS.CLUSTER]     : {
        key  : ADMIN_TABS_KEYS.CLUSTER,
        icon : 'device_hub',
        label: '',
    },
    [ADMIN_TABS_KEYS.AUDIT_LOGS]     : {
        key  : ADMIN_TABS_KEYS.AUDIT_LOGS,
        icon : 'format_list_bulleted',
        label: '',
    },
};

export const STREAM_INFO_TABS = {
    [STREAM_INFO_TABS_KEYS.INFO]        : {
        key     : STREAM_INFO_TABS_KEYS.INFO,
        icon    : 'info',
        label   : '',
        disabled: false,
    },
    [STREAM_INFO_TABS_KEYS.PERMISSION]  : {
        key     : STREAM_INFO_TABS_KEYS.PERMISSION,
        icon    : 'security',
        label   : '',
        disabled: false,
    },
    [STREAM_INFO_TABS_KEYS.SETTINGS]    : {
        key     : STREAM_INFO_TABS_KEYS.SETTINGS,
        icon    : 'settings',
        label   : '',
        disabled: false,
    },
    [STREAM_INFO_TABS_KEYS.STREAM_DEBUG]: {
        key  : STREAM_INFO_TABS_KEYS.STREAM_DEBUG,
        icon : 'bug_report',
        label: '',
    },
};

export const USER_TABS = {
    [USER_TABS_KEYS.INFO]      : {
        key     : USER_TABS_KEYS.INFO,
        icon    : 'info',
        label   : '',
        disabled: false,
    },
    [USER_TABS_KEYS.PERMISSION]: {
        key     : USER_TABS_KEYS.PERMISSION,
        icon    : 'security',
        label   : '',
        disabled: false,
    },
    [USER_TABS_KEYS.EDIT]      : {
        key     : USER_TABS_KEYS.EDIT,
        icon    : 'settings',
        label   : '',
        disabled: false,
    },
    [USER_TABS_KEYS.AUTH_TOKEN]: {
        key     : USER_TABS_KEYS.AUTH_TOKEN,
        icon    : 'devices',
        label   : '',
        disabled: false,
    },
};

export const PROFILE_TABS = {
    [PROFILE_TABS_KEYS.INFO]      : {
        key     : PROFILE_TABS_KEYS.INFO,
        icon    : 'info',
        label   : '',
        disabled: false,
    },
    [PROFILE_TABS_KEYS.TOKEN]     : {
        key     : PROFILE_TABS_KEYS.TOKEN,
        icon    : 'vpn_key',
        label   : '',
        disabled: false,
    },
    [PROFILE_TABS_KEYS.EDIT]      : {
        key     : PROFILE_TABS_KEYS.EDIT,
        icon    : 'settings',
        label   : '',
        disabled: false,
    },
    [PROFILE_TABS_KEYS.AUTH_TOKEN]: {
        key     : PROFILE_TABS_KEYS.AUTH_TOKEN,
        icon    : 'devices',
        label   : '',
        disabled: false,
    },
};

export const VIEW_MODES = {
    [VIEW_MODES_KEYS.GRID] : {
        key  : 'GRID',
        class: 'grid-mode',
        icon : 'view_module',
    },
    [VIEW_MODES_KEYS.TABLE]: {
        key  : 'TABLE',
        class: 'table-mode',
        icon : 'view_list',
    },
};

export const SORT_TYPES = {
    [SORT_TYPES_KEYS.DATE]    : {
        key : 'DATE',
        icon: '',
    },
    [SORT_TYPES_KEYS.NAME]    : {
        key : 'NAME',
        icon: '',
    },
    [SORT_TYPES_KEYS.LOCATION]: {
        key : 'LOCATION',
        icon: '',
    },
};

export const GROUP_TYPES = {
    [GROUP_TYPES_KEYS.TAG]     : {
        key : GROUP_TYPES_KEYS.TAG,
        icon: 'label',
    },
    [GROUP_TYPES_KEYS.NO_GROUP]: {
        key : GROUP_TYPES_KEYS.NO_GROUP,
        icon: 'label_outline',
    },
};

export const STREAM_STATUS = {
    RUNNING : 'running',
    PENDING : 'pending',
    PAUSED  : 'paused',
    CANCELED: 'canceled',
    STOPPED : 'stopped',
    FAILED  : 'failed',
};

export const PLAYER_VIEW_MODES = {
    LIVE   : 'LIVE',
    ARCHIVE: 'ARCHIVE',
};

export const USER_ACCESSES = {
    active : 'active',
    blocked: 'blocked',
};

export const MOVE_TIME                 = 200;
export const PANEL_SIZE                = 45;
export const DEFAULT_DEPTH             = 168;
export const DEFAULT_PLAYLIST_DURATION = 600;
export const EN                        = 'en';
export const EN_US                     = 'en-us';
export const RU                        = 'ru';
export const UK                        = 'uk';
export const ES                        = 'es';
export const PT                        = 'pt';
export const TR                        = 'tr';

export const SORTING_TYPES = {
    desc   : 'desc',
    asc    : 'asc',
    nonSort: 'nonSort',
};

export const LANG_MAP = {
    [EN_US]: EN,
    [EN]   : EN,
    [RU]   : RU,
    [UK]   : UK,
    [ES]   : ES,
    [PT]   : PT,
    [TR]   : TR,
};

export const PERMISSION_OBJECTS = {
    USER: 'USER',
    TEAM: 'TEAM',
};

export const COLORS = {
    dark : '#4b4d64',
    stone: '#666',
    wolf : '#999',
};

export const BREAKPOINTS_720 = 720;

export const SCROLL_WIDTH = getScrollWidth();

export const FEATURES = {
    cluster       : 'Cluster',
    auditLogs     : 'AuditLogs',
    branding      : 'Branding',
    personalTokens: 'PersonalTokens',
    users         : 'Users',
    streamShare   : 'StreamShare',
    tunnel        : 'Tunnel',
    teams         : 'Teams',
    notification  : 'Notification',
    quota         : 'Quota',
    streamAcl     : 'StreamAcl',
    gateway       : 'Gateway',
};

export const LIMITS = {
    clusterSize     : 'cluster_size',
    gatewayFreeLimit: 'gateway_free_limit',
    userLimit       : 'user_limit',
};

export const PRODUCTS = {
    plus      : 'Plus',
    enterprise: 'Enterprise',
    trial     : 'Trial',
};

export const LOCALS = {
    RU: 'ru',
    NL: 'nl',
    FEATURES,
    LIMITS,
};

export const CLUSTER_ROLES = {
    member   : 'member',
    leader   : 'leader',
    pretender: 'pretender',
    unknown  : 'unknown',
};
