/* eslint-disable import/no-cycle */
import httpClient from '@/config/httpClient';
import {
    PASSWORD_SEND_RESET_URL,
    PASSWORD_RESET_URL,
}                 from '@/constants/api';
import {
    RECOVERY_CODE_SENT,
    PASSWORD_CHANGED,
}                 from '@/constants/noticeTypes';

export default {
    namespaced: true,
    state     : {
        isSendingCode     : false,
        isResetInProcess  : false,
        retryAfterTime    : 0,
    },
    actions   : {
        async sendCode ({ commit, dispatch }, { email }) {
            commit('setIsSendingCode', { isSendingCode: true });

            await httpClient
                .post(PASSWORD_SEND_RESET_URL, { email })
                .then((res) => {
                    commit(
                        'setNotice',
                        { name: RECOVERY_CODE_SENT },
                        { root: true },
                    );
                    dispatch('disableSendingCode', {
                        delayTime: res.headers['retry-after']
                            ? res.headers['retry-after'] * 1000
                            : 0,
                    });
                })
                .catch((err) => {
                    dispatch('disableSendingCode', {
                        delayTime: err.response.headers['retry-after']
                            ? err.response.headers['retry-after'] * 1000
                            : 0,
                    });
                })
                .finally(() => {
                    commit('setIsSendingCode', { isSendingCode: false });
                });
        },
        async reset ({ commit }, { code, password, email }) {
            commit('setIsResetInProcess', { isResetInProcess: true });

            await httpClient
                .post(PASSWORD_RESET_URL, { code, password, email })
                .then(() => {
                    commit(
                        'setNotice',
                        { name: PASSWORD_CHANGED },
                        { root: true },
                    );
                })
                .finally(() => {
                    commit('setIsResetInProcess', { isResetInProcess: false });
                });
        },
        disableSendingCode ({ commit }, { delayTime }) {
            let timerTime = delayTime;
            commit('setRetryAfterTime', { retryAfterTime: timerTime });
            const timer   = setInterval(() => {
                timerTime -= 1000;
                commit('setRetryAfterTime', { retryAfterTime: timerTime });
            }, 1000);

            setTimeout(() => {
                commit('setRetryAfterTime', { retryAfterTime: 0 });
                clearInterval(timer);
            }, delayTime);
        },
    },
    mutations : {
        setIsResetInProcess (state, { isResetInProcess }) {
            state.isResetInProcess = isResetInProcess;
        },
        setIsSendingCode (state, { isSendingCode }) {
            state.isSendingCode = isSendingCode;
        },
        setRetryAfterTime (state, { retryAfterTime }) {
            state.retryAfterTime = retryAfterTime / 1000;
        },
    },
};
