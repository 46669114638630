/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-cycle */
import dayjs                 from 'dayjs';
import axios                 from 'axios';
import { PLAYER_VIEW_MODES } from '@/constants/main';
import { cloneObjDeep }      from '@/extends/lib';
import {
    getArchiveStatsUrl,
    getAvailableDaysUrl,
    getRangesUrl,
    ANNOTATIONS_URL,
    getDownloadArchiveUrl,
}                            from '@/constants/api';
import httpClient            from '@/config/httpClient';
import { RANGE_TYPES }       from '@/components/ycc-player/constants';
import ProgressBarController from '@/components/ycc-player/extends/ProgressBarController';
import {
    getDefaultStart,
    getDefaultEnd,
    getDefaultPeriod,
    getDefaultDownloadLimitsPX,
    getDefaultPeriodISO,
}                            from './lib';

const { CancelToken } = axios;

export default {
    namespaced: true,
    state     : {
        archiveStats      : null,
        availableDays     : [],
        annotations       : [],
        annotationsByTypes: [],

        speedIndex               : 1,
        archiveDownloadMaxSeconds: 3600,
        downloadLimitsPX         : getDefaultDownloadLimitsPX(),

        isVisibleDownloadBlock : false,
        isFetchingArchive      : false,
        isFetchingArchiveRanges: false,
        isFetchingAnnotations  : false,
        isPlaying              : false,
        isLoading              : false,

        cancelArchiveRangesRequest: null,
        archiveDataWatcherTimer   : null,

        /* progress-bar period */
        period   : getDefaultPeriod(),
        periodISO: getDefaultPeriodISO(),

        /* progress-bar ranges */
        ranges   : [],
        intervals: [],

        /* live/archive */
        playerViewMode: PLAYER_VIEW_MODES.LIVE,
        playlist      : null,

        progressBarController: null,
    },
    getters   : {
        hasArchive          : (state) => !!state.archiveStats && !!state.archiveStats.period,
        archiveDownloadMaxPX: (state) => {
            return state.progressBarController
                ? state.progressBarController.getPXFromSeconds(state.archiveDownloadMaxSeconds)
                : 0;
        },
        getSecondsInPx      : (state) => () => {
            return state.progressBarController ? state.progressBarController.secondsInPx() : 0;
        },
    },
    actions   : {
        /* bring */
        zoomUpByPointer ({ commit, state, getters, dispatch }, { streamUid, streamId }) {
            dispatch('zoomUp', {
                streamUid,
                streamId,
                period: state.progressBarController.getPeriodForZoomUpByPointer(),
            });
        },
        zoomUp ({ commit, state, getters, dispatch }, { streamUid, streamId, period }) {
            if (state.progressBarController.isZoomUpAllowed) {
                dispatch('updatePeriod', {
                    period,
                    streamId,
                    rangesUrl      : getRangesUrl(streamUid),
                    isArchiveEnable: true,
                    needRunWatcher : true,
                    needFetchData  : true,
                });
            }
        },
        /* put away */
        zoomDownByPointer ({ commit, state, getters, dispatch }, { streamUid, streamId }) {
            dispatch('zoomDown', {
                streamUid,
                streamId,
                period: state.progressBarController.getPeriodForZoomDownByPointer(),
            });
        },
        zoomDown ({ commit, state, getters, dispatch }, { streamUid, streamId, period }) {
            if (state.progressBarController.isZoomDownAllowed) {
                dispatch('updatePeriod', {
                    period,
                    streamId,
                    rangesUrl      : getRangesUrl(streamUid),
                    isArchiveEnable: true,
                    needRunWatcher : true,
                    needFetchData  : true,
                });
            }
        },
        toCenter ({ commit, state, getters, dispatch }, { streamUid, streamId }) {
            if (state.playerViewMode === PLAYER_VIEW_MODES.LIVE && !state.playlist) {
                return;
            }

            dispatch('updatePeriod', {
                streamId,
                period         : state.progressBarController.getPeriodForGoToCenter(),
                rangesUrl      : getRangesUrl(streamUid),
                isArchiveEnable: true,
                needRunWatcher : true,
                needFetchData  : true,
            });
        },
        moveLeft ({ commit, state, getters, dispatch }, { streamUid, streamId }) {
            dispatch('updatePeriod', {
                streamId,
                period         : state.progressBarController.getPeriodForMoveLeft(),
                rangesUrl      : getRangesUrl(streamUid),
                isArchiveEnable: true,
                needRunWatcher : true,
                needFetchData  : true,
            });
        },
        moveRight ({ commit, state, getters, dispatch }, { streamUid, streamId }) {
            dispatch('updatePeriod', {
                streamId,
                period         : state.progressBarController.getPeriodForMoveRight(),
                rangesUrl      : getRangesUrl(streamUid),
                isArchiveEnable: true,
                needRunWatcher : true,
                needFetchData  : true,
            });
        },
        updatePeriodAfterDrag ({ commit, dispatch, state }, { period }) {
            dispatch('updatePeriod', {
                period,
                isArchiveEnable: true,
                needRunWatcher : false,
                needFetchData  : false,
            });
        },
        resetPeriod ({ dispatch }) {
            dispatch('updatePeriod', {
                period: {
                    start: getDefaultStart(),
                    end  : getDefaultEnd(),
                },
            });
        },

        updateRanges ({ dispatch, commit }, { ranges }) {
            function getSplitLastRange () {
                const lastRange = ranges && ranges[ranges.length - 1]
                    ? cloneObjDeep(ranges[ranges.length - 1])
                    : null;

                if (lastRange && !lastRange.status && dayjs(lastRange.date).valueOf() <= Date.now()) {
                    lastRange.date = dayjs().toISOString();
                    return lastRange;
                }
                return null;
            }

            const liveRange = getSplitLastRange();

            if (liveRange) {
                ranges.push(liveRange);
            }

            dispatch('saveRanges', { ranges });
        },
        saveRanges ({ state, commit }, { ranges }) {
            state.progressBarController.setRanges(ranges);
            commit('setRanges', { ranges });
            commit('setIntervals', { intervals: state.progressBarController.intervals });
        },
        updatePlaylist ({ commit, state }, { url, period, mode }) {
            if (mode === PLAYER_VIEW_MODES.ARCHIVE) {
                const startTime = dayjs(new Date(period.start)).toISOString();
                const endTime   = dayjs(new Date(period.end)).toISOString();

                commit('setPlaylist', {
                    playlist: {
                        period,
                        url: `${url}?start_time=${startTime}&end_time=${endTime}`,
                    },
                });
            }
            else {
                commit('setPlaylist', { playlist: { url } });
            }
        },

        fetchArchive (
            { commit, dispatch, getters, state },
            { startTime, endTime, streamUid, streamId, isArchiveEnable },
        ) {
            if (isArchiveEnable) {
                commit('setFetching', { isFetchingArchive: true });

                httpClient
                    .get(getRangesUrl(streamUid), {
                        params: {
                            start_time: startTime,
                            end_time  : endTime,
                        },
                    })
                    .then((response) => {
                        if (response.data.period) {
                            dispatch('updatePeriod', {
                                period: {
                                    start: dayjs(new Date(response.data.period.startTime)).valueOf(),
                                    end  : dayjs(new Date(response.data.period.endTime)).valueOf(),
                                },
                            });
                            dispatch('updateRanges', { ranges: response.data.ranges });
                            dispatch('fetchAnnotations', { streamUid, isArchiveEnable, streamId });
                            dispatch('fetchArchiveStats', { streamUid, isArchiveEnable });
                        }
                    })
                    .finally(() => {
                        commit('setFetching', { isFetchingArchive: false });
                    });
            }
        },
        fetchArchiveRanges ({ commit, dispatch, getters, state }, { rangesUrl, isArchiveEnable }) {
            if (isArchiveEnable) {
                commit('setFetchingArchiveRanges', { isFetchingArchiveRanges: true });

                httpClient
                    .get(rangesUrl, {
                        params     : {
                            start_time: state.progressBarController.periodISO.start,
                            end_time  : state.progressBarController.periodISO.end,
                        },
                        cancelToken: new CancelToken((cancel) => {
                            state.cancelArchiveRangesRequest = cancel;
                        }),
                    })
                    .then((response) => {
                        if (response.data.period) {
                            dispatch('updateRanges', { ranges: response.data.ranges });
                        }
                        state.cancelArchiveRangesRequest = null;
                    })
                    .finally(() => {
                        commit('setFetchingArchiveRanges', { isFetchingArchiveRanges: false });
                    });
            }
        },
        fetchArchiveStats ({ commit, dispatch }, { streamUid, isArchiveEnable }) {
            if (isArchiveEnable) {
                const url = getArchiveStatsUrl(streamUid);

                httpClient
                    .get(url)
                    .then((res) => {
                        if (res.data.period) {
                            dispatch('updateArchiveStats', { stats: res.data });
                            dispatch(
                                'fetchAvailableDays',
                                {
                                    startDay: res.data.period.startTime,
                                    endDay  : res.data.period.endTime,
                                    streamUid,
                                    isArchiveEnable,
                                },
                            );
                        }
                    });
            }
        },
        fetchAvailableDays ({ commit, dispatch, getters, state }, { startDay, endDay, streamUid, isArchiveEnable }) {
            if (isArchiveEnable) {
                const url = getAvailableDaysUrl(streamUid);

                httpClient
                    .get(url, {
                        params: {
                            start_time: startDay,
                            end_time  : endDay,
                        },
                    })
                    .then((res) => {
                        commit('setAvailableDays', { availableDays: res.data });
                    });
            }
        },
        fetchArchiveData ({ dispatch }, { rangesUrl, isArchiveEnable, streamId }) {
            dispatch('fetchArchiveRanges', { rangesUrl, isArchiveEnable, streamId });
            dispatch('fetchAnnotations', { isArchiveEnable, streamId });
        },
        fetchAnnotations ({ state, dispatch, commit }, { isArchiveEnable, streamId }) {
            if (isArchiveEnable) {
                commit('setFetchingAnnotations', { isFetchingAnnotations: true });

                httpClient
                    .get(ANNOTATIONS_URL, {
                        params: {
                            streamId,
                            from: state.progressBarController.periodISO.start,
                            to  : state.progressBarController.periodISO.end,
                        },
                    })
                    .then((res) => {
                        dispatch('updateAnnotations', { annotations: res.data });
                    })
                    .finally(() => {
                        commit('setFetchingAnnotations', { isFetchingAnnotations: false });
                    });
            }
        },
        fetchDownloadArchiveUrl ({ commit, getters }, { streamUid, downloadPeriod }) {
            return httpClient
                .get(getDownloadArchiveUrl({ streamUid }), {
                    params: {
                        start_time     : dayjs(downloadPeriod.start).toISOString(),
                        end_time       : dayjs(downloadPeriod.end).toISOString(),
                        timezone_offset: dayjs().utcOffset(),
                    },
                })
                .then((res) => res.data);
        },

        updateAnnotations ({ state, commit }, { annotations }) {
            commit('setAnnotations', { annotations });
            commit('setAnnotationsByTypes', {
                annotations: state.progressBarController.getAnnotationsByTypes(annotations),
            });
        },
        resetArchiveData ({ commit, dispatch }) {
            commit('setAvailableDays', { availableDays: [] });
            commit('setPlaylist', { playlist: null });
            dispatch('updateAnnotations', { annotations: [] });
            dispatch('updateArchiveStats', { stats: null });
            dispatch('saveRanges', { ranges: [] });
            dispatch('updatePeriod', { period: getDefaultPeriod() });
            dispatch('updatePlayerViewMode', { mode: PLAYER_VIEW_MODES.LIVE });
        },
        createArchiveDataWatcher ({ commit, dispatch, getters }, { rangesUrl, isArchiveEnable, streamId }) {
            dispatch('clearArchiveDataWatcher');

            function getTime (secondsInPx) {
                return secondsInPx * 6 * 1000 > 3500 ? secondsInPx * 6 * 1000 : 3500;
            }

            const time = getTime(getters.getSecondsInPx());

            let timer = setTimeout(function tick () {
                dispatch('fetchArchiveData', { rangesUrl, isArchiveEnable, streamId });

                timer = setTimeout(tick, time);
                commit('setArchiveDataWatcherTimer', { timer });
            }, time);

            commit('setArchiveDataWatcherTimer', { timer });
        },
        clearArchiveDataWatcher ({ state }) {
            clearTimeout(state.archiveDataWatcherTimer);
        },

        /* for Player */
        updateSpeedIndex ({ commit }, { speedIndex }) {
            commit('setSpeedIndex', { speedIndex });
        },
        goToLive ({ dispatch, getters, commit }, {
            isSeek,
            rangesUrl,
            liveUrl,
            isAvailableStream,
            isArchiveEnable,
            streamId,
            needFetchData,
        }) {
            dispatch('updatePlayerViewMode', { mode: PLAYER_VIEW_MODES.LIVE });

            if (isAvailableStream) {
                if (!isSeek) {
                    dispatch('updatePeriod', {
                        rangesUrl,
                        isArchiveEnable,
                        streamId,
                        needFetchData,
                        period: {
                            start: dayjs(new Date(getDefaultStart())).valueOf(),
                            end  : dayjs(new Date(getDefaultEnd())).valueOf(),
                        },
                    });
                }
                dispatch('updatePlaylist', {
                    url   : liveUrl,
                    mode  : PLAYER_VIEW_MODES.LIVE,
                    period: null,
                });
            }
            else {
                dispatch('goToEmptyState');
            }
        },
        goToArchive ({ dispatch, state }, { period, playlistUrl }) {
            dispatch('updatePlaylist', {
                period,
                url : playlistUrl,
                mode: PLAYER_VIEW_MODES.ARCHIVE,
            });
            state.progressBarController.updatePlayingTime(period.start);
            dispatch('updatePlayerViewMode', { mode: PLAYER_VIEW_MODES.ARCHIVE });
        },
        goToEmptyState ({ commit, state }) {
            state.progressBarController.updatePlayingTime(0);
            commit('setPlaylist', { playlist: null });
        },
        updatePlayerViewMode ({ state, commit, dispatch }, { mode }) {
            commit('setPlayerViewMode', { mode });

            if (mode === PLAYER_VIEW_MODES.LIVE) {
                dispatch('updateSpeedIndex', { speedIndex: 1 });
            }
        },
        updateArchiveStats ({ state, commit }, { stats }) {
            if (state.progressBarController) {
                state.progressBarController.setArchiveStatsPeriod(stats?.period || null);
                // todo: requests queue
                commit('setIntervals', { intervals: state.progressBarController.intervals });
            }
            commit('setArchiveStats', { stats });
        },
        handleEndedVideo ({ dispatch, state }, { playlistUrl, liveUrl, isAvailableStream }) {
            const period = state.progressBarController.getNewPlaylistPeriodAfterEnding();

            if (period.type === RANGE_TYPES.FRAGMENT) {
                dispatch('goToArchive', {
                    period,
                    playlistUrl,
                });
            }
            else {
                dispatch('goToLive', {
                    liveUrl,
                    isAvailableStream,
                    isSeek: true,
                });
            }
        },

        updatePeriod ({ commit, dispatch, state }, {
            period,
            rangesUrl,
            isArchiveEnable,
            streamId,
            needRunWatcher,
            needFetchData,
        }) {
            dispatch('setPeriod', { period })
                .then(() => {
                    if (needFetchData) {
                        dispatch('fetchArchiveData', { rangesUrl, isArchiveEnable, streamId });
                    }

                    if (needRunWatcher) {
                        dispatch('createArchiveDataWatcher', { rangesUrl, streamId, isArchiveEnable });
                    }

                    if (!state.isPlaying) {
                        state.progressBarController.updateThumbPos(
                            state.progressBarController.playingTime,
                            state.progressBarController.period.start,
                        );
                    }
                });
        },
        setPeriod ({ state, commit }, { period }) {
            state.progressBarController.setPeriod(period);
            commit('setPeriod', { period });
            commit('setPeriodISO', { periodISO: state.progressBarController.periodISO });
            commit('setIntervals', { intervals: state.progressBarController.intervals });
        },

        buildProgressBarController ({ state, commit }, {
            playerId,
            thumbWidth,
        }) {
            commit('setProgressBarController', {
                progressBarController: new ProgressBarController({
                    playerId,
                    thumbWidth,
                }),
            });
        },
    },
    mutations : {
        setPeriod (state, { period }) {
            state.period = period;
        },
        setPeriodISO (state, { periodISO }) {
            state.periodISO = periodISO;
        },
        setPlaylist (state, { playlist }) {
            state.playlist = playlist;
        },
        setRanges (state, { ranges }) {
            state.ranges = ranges;
        },
        setPlayerViewMode (state, { mode }) {
            state.playerViewMode = mode;
        },
        setFetching (state, { isFetchingArchive }) {
            state.isFetchingArchive = isFetchingArchive;
        },
        setAvailableDays (state, { availableDays }) {
            state.availableDays = availableDays;
        },
        setFetchingArchiveRanges (state, { isFetchingArchiveRanges }) {
            state.isFetchingArchiveRanges = isFetchingArchiveRanges;
        },
        setArchiveStats (state, { stats }) {
            state.archiveStats = stats;
        },
        setArchiveDataWatcherTimer (state, { timer }) {
            state.archiveDataWatcherTimer = timer;
        },
        setDownloadBlockVisibility (state, { isVisibleDownloadBlock }) {
            state.isVisibleDownloadBlock = isVisibleDownloadBlock;
        },
        setDownloadLimits (state, { downloadLimitsPX }) {
            state.downloadLimitsPX = downloadLimitsPX;
        },
        setArchiveDownloadMaxSeconds (state, { archiveDownloadMaxSeconds }) {
            state.archiveDownloadMaxSeconds = archiveDownloadMaxSeconds;
        },
        setAnnotations (state, { annotations }) {
            state.annotations = annotations;
        },
        setAnnotationsByTypes (state, { annotations }) {
            state.annotationsByTypes = annotations;
        },
        setFetchingAnnotations (state, { isFetchingAnnotations }) {
            state.isFetchingAnnotations = isFetchingAnnotations;
        },
        setSpeedIndex (state, { speedIndex }) {
            state.speedIndex = speedIndex;
        },
        setPlayingState (state, { isPlaying }) {
            state.isPlaying = isPlaying;
        },
        setLoadingState (state, { isLoading }) {
            state.isLoading = isLoading;
        },
        setIntervals (state, { intervals }) {
            state.intervals = intervals;
        },
        setProgressBarController (state, { progressBarController }) {
            state.progressBarController = progressBarController;
        },
    },
};
