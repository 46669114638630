const TOP           = 'top';
const TOP_LTR       = 'top-ltr';
const TOP_RTL       = 'top-rtl';
const TOP_CENTER    = 'top-center';
const BOTTOM        = 'bottom';
const BOTTOM_LTR    = 'bottom-ltr';
const BOTTOM_RTL    = 'bottom-rtl';
const BOTTOM_CENTER = 'bottom-center';
const LEFT          = 'left';
const RIGHT         = 'right';

export {
    TOP,
    TOP_LTR,
    TOP_RTL,
    TOP_CENTER,
    BOTTOM,
    BOTTOM_LTR,
    BOTTOM_RTL,
    BOTTOM_CENTER,
    LEFT,
    RIGHT,
};
