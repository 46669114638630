const isExist = (value) => value !== null && value !== undefined;

export default class StorageAdapter {
    constructor ({ itemName, isPrimitive }, storage = localStorage) {
        this.itemName    = itemName;
        this.isPrimitive = isPrimitive;
        this.storage     = storage || null;
    }

    getCorrectValue = (value, isPrimitive, correctionFunc) => isPrimitive ? value : correctionFunc(value);

    getItem () {
        return this.storage.getItem(this.itemName)
            ? this.getCorrectValue(this.storage.getItem(this.itemName), this.isPrimitive, JSON.parse)
            : null;
    }

    setItem (value) {
        if (isExist(value)) {
            this.storage.setItem(this.itemName, this.getCorrectValue(value, this.isPrimitive, JSON.stringify));
        }
    }

    removeItem () {
        this.storage.removeItem(this.itemName);
    }
}
