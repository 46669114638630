import { getCookie } from '@/extends/lib';

export default class CookieService {
    static getDeviceOs () {
        return getCookie('device_os');
    }

    static getShowServerSwitcher () {
        const cookie = getCookie('show_server_switcher');
        return cookie ? JSON.parse(cookie) : null;
    }
}
