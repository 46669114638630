<template>
    <div class="sgc-dropdown"
         :id="uniqueKey"
         :class="[{'open': menuState.state}]"
         :disabled="disabled"
         @click="changeState">
        <slot></slot>
    </div>
</template>

<script>
import {
    NOT_CLOSING,
    CLOSING,
    SUBMENU,
    MENU_CONTENT,
    SHOW,
} from './constants';

export default {
    name : 'YccDropdown',
    props: {
        uniqueKey        : {
            type   : String,
            default: 'sgc-dropdown-defaultId',
        },
        dropdownMenuState: {
            type: Object,
            default () {
                return {
                    state      : false,
                    visibleMenu: '',
                };
            },
        },
        stateFromProps   : {
            type   : Boolean,
            default: false,
        },
        disabled         : Boolean,
    },

    data () {
        return {
            state      : false,
            visibleMenu: '',
        };
    },

    computed: {
        menuState () {
            return this.stateFromProps ? this.dropdownMenuState : { state: this.state };
        },
    },
    watch   : {
        dropdownMenuState: {
            handler (newVal) {
                this.state       = newVal.state;
                this.visibleMenu = newVal.visibleMenu;

                this.showSubmenu(newVal);
            },
            immediate: true,
            deep     : true,
        },
        state (newState) {
            if (newState) {
                window.addEventListener('click', this.closeDropdownMenu);
                this.$emit('open');
            }
            else {
                window.removeEventListener('click', this.closeDropdownMenu);
                this.$emit('close');
            }
        },
    },

    methods: {
        // изменить состояние меню
        changeState (event) {
            if (this.disabled) {
                return false;
            }

            // обработка подменю
            if (this.isChildElem(SUBMENU, event.target)) {
                if (this.isChildElem(MENU_CONTENT, event.target)) {
                    this.state       = true;
                    this.visibleMenu = '';
                    this.deleteShowClass();
                    this.shareState();
                    return false;
                }

                // ищем элумент обертку подменю
                let elem = event.target;
                while (!elem.classList.contains(SUBMENU)) {
                    elem = elem.parentElement;
                }
                // добавляем/удаляем класс show
                elem.classList.toggle(SHOW);
                this.deleteShowClass(elem);

                // запоминаем состояние подменю
                if (this.visibleMenu === elem.dataset.sgcUniqueClass) {
                    this.visibleMenu = '';
                }
                else {
                    this.visibleMenu = elem.dataset.sgcUniqueClass;
                }
                this.shareState();
                event.stopPropagation();
                return false;
            }
            // обработка закрывающих меню элементов
            if (this.isChildElem(CLOSING, event.target)) {
                this.state       = false;
                this.visibleMenu = '';
                this.deleteShowClass();
                this.shareState();
                return false;
            }
            // обработка незакрывающих меню элементов
            if (this.isChildElem(NOT_CLOSING, event.target)
                || this.isChildElem(SUBMENU, event.target)) {
                this.state = true;
                this.shareState();
                return false;
            }
            this.state = !this.state;
            this.shareState();
            event.preventDefault();
            return false;
        },

        // закрывает меню при клике за его пределами
        closeDropdownMenu (event) {
            if (event && this.isChildElem('', event.target)) {
                return false;
            }

            this.state       = false;
            this.visibleMenu = '';
            this.deleteShowClass();
            this.shareState();
            return false;
        },

        // поделиться состоянием
        shareState () {
            if (this.stateFromProps) {
                const state = { state: this.state, visibleMenu: this.visibleMenu };
                this.$emit('dispatch-dropdown-menu-state', state);
            }
        },
        //
        showSubmenu (dropdownMenuState) {
            if (this.stateFromProps
                && dropdownMenuState.visibleMenu
                && dropdownMenuState.visibleMenu.length > 0) {
                /* eslint-disable-next-line */
                const submenuList = this.findElems(`.sgc-dropdown-submenu[data-sgc-unique-class=${dropdownMenuState.visibleMenu}]`);
                [].forEach.call(submenuList, (e) => e.classList.add(SHOW));
            }
        },

        // проверяет является ли элемент потомком класса
        isChildElem (className, elem) {
            let selector = '';
            if (className.length > 0) {
                selector = `.${className}`;
            }
            const notClosingElems = this.findElems(`#${this.uniqueKey} ${selector} *`);
            for (let i = 0; i < notClosingElems.length; i++) {
                if (elem === notClosingElems[i]) {
                    return true;
                }
            }
            return false;
        },
        // удаляет класс open
        deleteShowClass (elem) {
            const elems = this.findElems(`#${this.uniqueKey} .sgc-dropdown-submenu.open`);
            [].forEach.call(elems, (e) => {
                if (e !== elem) {
                    e.classList.remove(SHOW);
                }
            });
        },

        // для поиска элементов DOM
        findElems (selector) {
            return document.querySelectorAll(selector);
        },
    },
};
</script>

<style lang="scss"
       scoped>
@import "dropdown-ycc";
</style>
