import {
    TOP,
    TOP_LTR,
    TOP_RTL,
    TOP_CENTER,
    BOTTOM,
    BOTTOM_LTR,
    BOTTOM_RTL,
    BOTTOM_CENTER,
    LEFT,
    RIGHT,
}                       from '@/constants/positionConstants';
import { SCROLL_WIDTH } from '@/constants/main';

const positions = {
    RTL   : 'rtl',
    LTR   : 'ltr',
    CENTER: 'center',
    TOP   : 'top',
    BOTTOM: 'bottom',
};

export default class CorrectingPosition {
    constructor ({ position, contentWidth = null }) {
        this.contentWidth       = contentWidth;
        this.position           = null;
        this.positionsArr       = null;
        this.basePosition       = null;
        this.horizontalPosition = null;
        this.documentWidth      = document.documentElement.clientWidth;
        this.documentHeight     = window.getComputedStyle(document.body).position === 'static'
        || window.getComputedStyle(document.body).position === ''
            ? document.documentElement.clientHeight
            // eslint-disable-next-line max-len
            : Math.max(document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight, document.body.clientHeight, document.documentElement.clientHeight);
        this.setPosition(position);
    }

    get ltrPositionClass () {
        return this.position === TOP ? TOP_LTR : BOTTOM_LTR;
    }

    get rtlPositionClass () {
        return this.position === TOP ? TOP_RTL : BOTTOM_RTL;
    }

    setPosition (position) {
        this.position           = position || BOTTOM_LTR;
        this.positionsArr       = position.split('-');
        this.basePosition       = this.positionsArr[0];
        this.horizontalPosition = this.positionsArr[1] ? this.positionsArr[1] : '';
    }

    getPositionCssClass (
        {
            contentCoords,
            btnCoords,
            isStaticVerticalPosition,
            position,
        },
    ) {
        this.setPosition(position);

        return this.getCorrectedPositionCssClass({
            contentCoords,
            btnCoords,
            isStaticVerticalPosition,
            position,
        });
    }

    getStyle ({ btnCoords, contentCoords, position }) {
        const popupStyle = {};

        switch (position) {
            case TOP:
                popupStyle.bottom = `${this.documentHeight - btnCoords.top}px`;
                popupStyle.left   = `${btnCoords.left + btnCoords.width / 2}px`;
                popupStyle.right  = 'auto';
                popupStyle.top    = 'auto';
                break;
            case BOTTOM:
                popupStyle.top    = `${btnCoords.top + btnCoords.height}px`;
                popupStyle.left   = `${btnCoords.left + btnCoords.width / 2}px`;
                popupStyle.right  = 'auto';
                popupStyle.bottom = 'auto';
                break;
            case LEFT:
                popupStyle.top    = `${btnCoords.top + btnCoords.height / 2}px`;
                popupStyle.right  = `${this.documentWidth - btnCoords.left}px`;
                popupStyle.left   = 'auto';
                popupStyle.bottom = 'auto';
                break;
            case RIGHT:
                popupStyle.top    = `${btnCoords.top + btnCoords.height / 2}px`;
                popupStyle.left   = `${btnCoords.left + btnCoords.width}px`;
                popupStyle.right  = 'auto';
                popupStyle.bottom = 'auto';
                break;
            case TOP_LTR:
                popupStyle.bottom = `${this.documentHeight - btnCoords.top}px`;
                popupStyle.left   = `${btnCoords.left}px`;
                popupStyle.right  = 'auto';
                popupStyle.top    = 'auto';
                break;
            case TOP_RTL:
                popupStyle.bottom = `${this.documentHeight - btnCoords.top}px`;
                popupStyle.right  = `${this.documentWidth - (btnCoords.left + btnCoords.width)}px`;
                popupStyle.top    = 'auto';
                popupStyle.left   = 'auto';
                break;
            case BOTTOM_RTL:
                popupStyle.top    = `${btnCoords.top + btnCoords.height}px`;
                popupStyle.right  = `${this.documentWidth - (btnCoords.left + btnCoords.width)}px`;
                popupStyle.left   = 'auto';
                popupStyle.bottom = 'auto';
                break;
            case TOP_CENTER:
                popupStyle.top       = `${btnCoords.top - 5}px`;
                popupStyle.bottom    = 'auto';
                popupStyle.right     = 'auto';
                popupStyle.left      = '50%';
                popupStyle.transform = 'translateX(-50%) translateY(-100%)';
                break;
            case BOTTOM_CENTER:
                popupStyle.top       = `${btnCoords.top + btnCoords.height + 5}px`;
                popupStyle.bottom    = 'auto';
                popupStyle.right     = 'auto';
                popupStyle.left      = '50%';
                popupStyle.transform = 'translateX(-50%)';
                break;
            case BOTTOM_LTR:
            default:
                popupStyle.top    = `${btnCoords.top + btnCoords.height}px`;
                popupStyle.left   = `${btnCoords.left}px`;
                popupStyle.right  = 'auto';
                popupStyle.bottom = 'auto';
                break;
        }

        if (this.isContentMoreThanDocumentWidth({ btnCoords, contentCoords })) {
            popupStyle.maxWidth = 'calc(100vw - 40px)';
        }
        return popupStyle;
    }

    getCorrectedPositionCssClass (
        {
            contentCoords,
            btnCoords,
            position,
            isDynamicVerticalPosition = true,
        },
    ) {
        // 1 этап - переопределение базовых позиций -> RTL\LTR
        if (position === LEFT
            && CorrectingPosition.isOutOfLeftBorderForLeft({ contentCoords, btnCoords })) {
            this.horizontalPosition = positions.LTR;
            this.basePosition       = positions.BOTTOM;
        }

        if (position === RIGHT
            && this.isOutOfRightBorderForRight({ contentCoords, btnCoords })) {
            this.horizontalPosition = positions.RTL;
            this.basePosition       = positions.BOTTOM;
        }

        if (position === BOTTOM || position === TOP) {
            if (this.isOutOfRightBorderForCenter({ contentCoords, btnCoords })) {
                this.horizontalPosition = positions.RTL;
            }
            if (CorrectingPosition.isOutOfLeftBorderForCenter({ contentCoords, btnCoords })) {
                this.horizontalPosition = positions.LTR;
            }
        }

        if (this.horizontalPosition) {
            // 2 этап - переопределение RTL -> LTR, LTR -> RTL
            if (this.horizontalPosition === positions.LTR
                && this.isOutOfRightBorderForLtr({ contentCoords, btnCoords })) {
                this.horizontalPosition = positions.RTL;
            }

            if (this.horizontalPosition === positions.RTL
                && CorrectingPosition.isOutOfLeftBorderForRtl({ contentCoords, btnCoords })) {
                this.horizontalPosition = positions.LTR;
            }

            // 3 этап - переопределение RTL\LTR -> Center (центр экрана)
            if (this.isContentMoreThanDocumentWidth({ contentCoords, btnCoords })) {
                this.horizontalPosition = positions.CENTER;
            }

            if (this.horizontalPosition === positions.RTL
                && this.isOutOfRightBorderForRtl({ contentCoords, btnCoords })) {
                this.horizontalPosition = '';
            }
        }

        // 4 этап - переопределение BOTTOM, BOTTOM_* <-> TOP, TOP_*
        if (isDynamicVerticalPosition) {
            if (this.basePosition === positions.BOTTOM
                && this.isOutOfBottomBorder({ contentCoords, btnCoords })) {
                this.basePosition = positions.TOP;
            }

            if (this.basePosition === positions.TOP
                && CorrectingPosition.isOutOfTopBorder({ contentCoords, btnCoords })) {
                this.basePosition = positions.BOTTOM;
            }
        }

        this.position = this.horizontalPosition
            ? `${this.basePosition}-${this.horizontalPosition}` : this.basePosition;

        return this.position;
    }

    isContentMoreThanDocumentWidth ({ contentCoords }) {
        let { width } = contentCoords;

        if (this.contentWidth) {
            width = this.contentWidth;
        }
        return width + SCROLL_WIDTH > this.documentWidth;
    }

    isOutOfRightBorderForCenter ({ contentCoords, btnCoords }) {
        return btnCoords.left + (btnCoords.width / 2) + (contentCoords.width / 2) > this.documentWidth;
    }

    isOutOfRightBorderForLtr ({ contentCoords, btnCoords }) {
        return btnCoords.left + contentCoords.width > this.documentWidth;
    }

    isOutOfRightBorderForRight ({ contentCoords, btnCoords }) {
        return btnCoords.left + btnCoords.width + contentCoords.width > this.documentWidth;
    }

    isOutOfRightBorderForRtl ({ btnCoords }) {
        return btnCoords.left + btnCoords.width > this.documentWidth;
    }

    isOutOfBottomBorder ({ contentCoords, btnCoords }) {
        return btnCoords.y + btnCoords.height + contentCoords.height > this.documentHeight;
    }

    static isOutOfTopBorder ({ contentCoords, btnCoords }) {
        return btnCoords.y - contentCoords.height < 0;
    }

    static isOutOfLeftBorderForCenter ({ contentCoords, btnCoords }) {
        return (btnCoords.left + (btnCoords.width / 2)) - (contentCoords.width / 2) < 0;
    }

    static isOutOfLeftBorderForRtl ({ contentCoords, btnCoords }) {
        return (btnCoords.left + btnCoords.width) - contentCoords.width < 0;
    }

    static isOutOfLeftBorderForLeft ({ contentCoords, btnCoords }) {
        return btnCoords.left - contentCoords.width < 0;
    }
}
