<template>
    <div class="datepicker-ycc datepicker-ycc_calendar"
         ref="datepicker"
         :style="datepickerStyles">
        <ycc-calendar ref="calendar"
                      :calendar-id="`${datepickerId}-calendar`"
                      :range-type="rangeType"
                      :lang="lang"
                      :start-date="startDate"
                      :number-of-month="correctedNumberOfMonth"
                      :is-highlight-today="isHighlightToday"
                      :is-highlight-weekend="isHighlightWeekend"
                      :period="period"
                      :max-range="maxRange"
                      :min-allowed-date="minAllowedDate"
                      :max-allowed-date="maxAllowedDate"
                      :use-controls="true"
                      :significant-dates="significantDates"
                      :is-disabled-date="isDisabledDate"
                      @decreased-month="decreaseMonth"
                      @increased-month="increaseMonth"
                      @updated-dates="onClickDay">
        </ycc-calendar>
    </div>
</template>

<script>
import dayjs       from 'dayjs';
import 'dayjs/locale/ru';
import YccCalendar from './YccCalendar.vue';
import {
    ISO_DATE_FORMAT,
    MAX_MONTH_IN_DATEPICKER,
    MAX_MONTH_IN_LINE,
    MONTH_WIDTH,
    TYPES,
}                  from './constants';

export default {
    name      : 'YccDatepickerContent',
    components: { YccCalendar },
    props     : {
        datepickerId      : {
            type   : String,
            default: 'calendar',
        },
        rangeType         : {
            type   : String,
            default: TYPES.SINGLE,
        },
        lang              : {
            type   : String,
            default: 'en',
        },
        maxRange          : {
            type   : Number,
            default: null,
        },
        start             : {
            type   : String,
            default: null,
        },
        end               : {
            type: String,
        },
        numberOfMonth     : {
            type   : Number,
            default: MAX_MONTH_IN_LINE,
        },
        // Минимально допустимая дата (предел слева)
        minAllowedDate    : {
            type   : [String, Boolean],
            default: null,
        },
        // Максимально допустимая дата (предел справа)
        maxAllowedDate    : {
            type   : [String, Boolean],
            default: null,
        },
        // Массив значимых дат (помеченных)
        significantDates  : {
            type: Array,
            default () {
                return [];
            },
        },
        location          : {
            type   : Object,
            default: null,
        },
        isHighlightToday  : {
            type   : Boolean,
            default: true,
        },
        isHighlightWeekend: Boolean,
        isInline          : Boolean,
        isSelecting       : Boolean,
        isOpen            : Boolean,
        isDisabledDate    : {
            type   : Function,
            default: null,
        },
    },
    data () {
        return {
            startDate       : null,
            datepickerStyles: {},
        };
    },
    created () {
        dayjs.locale(this.lang);
        this.refreshStartDate();
    },
    mounted () {
        this.datepickerStyles = this.getDatepickerStyles();
    },
    watch     : {
        datepickerId () {
            this.refreshStartDate();
        },
        isSelecting (state) {
            if (!state) {
                this.$refs.calendar.isSelecting = state;
            }
        },
        isOpen (state) {
            if (state) {
                this.$nextTick(() => {
                    this.datepickerStyles = this.getDatepickerStyles();
                });
            }
        },
    },
    computed  : {
        monthWidth () {
            return MONTH_WIDTH;
        },
        correctedNumberOfMonth () {
            if (this.numberOfMonth % MAX_MONTH_IN_LINE === 0
                || this.numberOfMonth < MAX_MONTH_IN_LINE) {
                return this.numberOfMonth;
            }
            if (this.numberOfMonth < MAX_MONTH_IN_DATEPICKER) {
                return Math.ceil(this.numberOfMonth / MAX_MONTH_IN_LINE) * MAX_MONTH_IN_LINE;
            }
            return MAX_MONTH_IN_DATEPICKER;
        },
        period () {
            return {
                start: this.start,
                end  : this.end && this.rangeType !== TYPES.SINGLE ? this.end : this.start,
            };
        },
    },
    methods   : {
        getDatepickerStyles () {
            if (this.location) {
                const { width, height } = this.$refs.datepicker.getBoundingClientRect();
                const documentWidth     = document.documentElement.clientWidth;
                const documentHeight    = document.documentElement.clientHeight;
                let top                 = this.location.top + this.location.height;
                let { left }            = this.location;

                if (top + height >= documentHeight) {
                    top = this.location.top - height;
                }

                if (left + width > documentWidth && width < documentWidth) {
                    left = documentWidth - width;
                }

                return {
                    left: `${left}px`,
                    top : `${top}px`,
                };
            }
            return {};
        },
        getStartDate () {
            let date          = this.start ? dayjs(this.start) : dayjs();
            const maxDate     = this.maxAllowedDate === true || this.maxAllowedDate === 'true'
                ? dayjs(new Date()) : dayjs(new Date(this.maxAllowedDate));
            const monthDiff   = maxDate.diff(date, 'month');
            let numberOfMonth = this.correctedNumberOfMonth - monthDiff - 1;

            numberOfMonth = numberOfMonth > 0 && monthDiff >= 0 ? numberOfMonth : 0;

            if (this.maxAllowedDate) {
                date = date.subtract(numberOfMonth, 'month');
            }

            return dayjs(new Date(date.year(), date.month(), 1)).format(ISO_DATE_FORMAT);
        },
        decreaseMonth () {
            this.startDate = dayjs(this.startDate).subtract(1, 'month').format(ISO_DATE_FORMAT);
            this.changeMonthYear(this.startDate);
        },
        increaseMonth () {
            this.startDate = dayjs(this.startDate).add(1, 'month').format(ISO_DATE_FORMAT);
            this.changeMonthYear(this.startDate);
        },
        changeMonthYear (startDate) {
            this.$emit('changed-month-year', {
                startDate,
                month: dayjs(startDate).month(),
                year : dayjs(startDate).year(),
            });
        },
        onClickDay ({ isSelecting, period }) {
            this.$emit('updated-dates', { isSelecting, period, datepickerId: this.datepickerId });
        },
        refreshStartDate () {
            this.startDate = this.getStartDate();
        },
    },
};
</script>
