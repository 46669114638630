import {
    EN,
    RU,
    UK,
    ES,
    PT,
    TR,
}                   from '@/constants/main';
import translations from './translations';

export default function gettextOptions (lang) {
    return {
        translations,
        availableLanguages: {
            [EN]: 'English',
            [RU]: 'Русский',
            [UK]: 'Українська',
            [ES]: 'Español',
            [PT]: 'Português',
            [TR]: 'Türkçe',
        },
        defaultLanguage   : lang || EN,
        silent            : true,
    };
}
