import translations from '../assets/lang/json/translations.json';

const en = {};
const ru = {
    ...translations.ru,
};
const uk = {
    ...translations.uk,
};
const es = {
    ...translations.es,
};
const pt = {
    ...translations.pt,
};
const tr = {
    ...translations.tr,
};

export default {
    en,
    ru,
    uk,
    es,
    pt,
    tr,
};
