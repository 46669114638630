/* eslint-disable import/no-cycle */
import axios  from 'axios';
import {
    ACCESS_DENIED_ERR,
    SERVER_ERR,
    SERVER_ERROR_CODES,
    SERVER_NOT_RESPONSE_ERR,
    SERVER_UNKNOWN_ERR,
}             from '@/constants/errorTypes';
import router from '@/router';
import store  from '@/store/store';

const $http = axios.create();

$http.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error),
);

$http.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            if (error.response.status === 404) {
                router.replace({ path: '/*' });
            }
            else if (error.response.status === 403
                && error.response.data.code !== SERVER_ERROR_CODES.QuotaReached) {
                store.dispatch('setError', {
                    errorMsg : ACCESS_DENIED_ERR,
                    errorName: SERVER_ERR,
                });
            }
            else if (error.response.data.code === SERVER_ERROR_CODES.AuditLogsDisabled) {
                return Promise.reject(error);
            }
            else if (error.response.status !== 401 && error.response.status !== 423) {
                store.dispatch('setError', {
                    errorMsg : error.response && error.response.data
                        ? error.response.data.code : SERVER_UNKNOWN_ERR,
                    errorName: SERVER_ERR,
                });
            }
        }
        else if (!axios.isCancel(error)) {
            store.dispatch('setError', {
                errorMsg : SERVER_NOT_RESPONSE_ERR,
                errorName: SERVER_ERR,
            });
        }
        return Promise.reject(error);
    },
);

export default $http;
