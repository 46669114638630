/* eslint-disable import/no-cycle */

import httpClient              from '@/config/httpClient';
import PAGES                   from '@/constants/pages';
import { GETTING_STREAMS_URL } from '@/constants/api';
import {
    GROUP_TYPES_KEYS,
    SORT_TYPES_KEYS,
    STREAM_STATUS,
    VIEW_MODES_KEYS,
}                              from '@/constants/main';
import {
    getGroupedStreams,
    saveToLocalStorage,
}                              from '@/store/utilities';

export default {
    namespaced: true,
    state     : {
        streams: [],

        viewModeKey        : VIEW_MODES_KEYS.GRID,
        groupType          : GROUP_TYPES_KEYS.NO_GROUP,
        sortType           : SORT_TYPES_KEYS.DATE, // for LocalStorage
        pageNumberOfStreams: 1,
        searchingValues    : [],

        isShownAvailableStreams: false,
        isFetchingStreams      : true,
        isCheckingMode         : false,
    },
    mutations : {
        setStreams (state, payload) {
            state.streams = [...payload.streams];
        },
        setGroupType (state, payload) {
            state.groupType = payload;
        },
        setSearchingValues (state, payload) {
            state.searchingValues = [...payload];
        },
        setViewModeKey (state, payload) {
            state.viewModeKey = payload;
        },
        setShowingAvailableState (state, payload) {
            state.isShownAvailableStreams = payload;
        },
        setStreamsStatuses (state, payload) {
            state.streams = [...payload.streams];
        },
    },
    getters   : {
        /* eslint-disable-next-line */
        availableStreams: (state) => {
            return state.streams.filter((stream) => stream.status === STREAM_STATUS.PENDING
                || stream.status === STREAM_STATUS.RUNNING);
        },
        searchedStreams : (state, getters) => {
            const uniqueStreamsIds = {};
            let allSearchedStreams = [];
            let streams            = state.isShownAvailableStreams ? getters.availableStreams : state.streams;

            function getValuesForSearching (stream) {
                let valuesForSearching = [stream.name, stream.streamSource];

                if (stream.tags && stream.tags.length > 0) {
                    valuesForSearching = [...valuesForSearching, ...stream.tags];
                }

                return valuesForSearching;
            }

            if (state.searchingValues.length > 0) {
                state.searchingValues.forEach((searchingValue) => {
                    streams.forEach((stream) => {
                        uniqueStreamsIds[stream.uid] = false;

                        const valuesForSearching = getValuesForSearching(stream);

                        if (stream.location && stream.location.name) {
                            valuesForSearching.push(stream.location.name);
                        }

                        valuesForSearching.forEach((item) => {
                            if (item
                                && item.toLowerCase().indexOf(searchingValue.toLowerCase()) !== -1
                                && uniqueStreamsIds[stream.uid] !== true) {
                                allSearchedStreams.push(stream);
                                uniqueStreamsIds[stream.uid] = true;
                            }
                        });
                    });
                    streams            = [...allSearchedStreams];
                    allSearchedStreams = [];
                });
            }
            return streams;
        },
        groupedStreams  : (state, getters) => {
            switch (state.groupType) {
                case GROUP_TYPES_KEYS.TAG:
                    return getters.groupedByTags;
                default:
                    return {
                        NO_GROUP: {
                            name   : 'All',
                            streams: getters.searchedStreams,
                        },
                    };
            }
        },
        groupedByTags   : (state, getters) => getGroupedStreams(getters.searchedStreams, GROUP_TYPES_KEYS.TAG),
        // AUTOCOMPLETE
        autocomplete: (state) => {
            const autocomplete = {
                names    : [],
                tags     : [],
                markers  : [],
                locations: [],
            };

            const markers   = {};
            const tags      = {};
            const locations = {};

            state.streams.forEach((stream) => {
                autocomplete.names.push({ value: stream.name });

                if (stream.meta && stream.meta.marker) {
                    markers[stream.meta.marker] = true;
                }

                if (stream.tags && stream.tags.length > 0) {
                    stream.tags.forEach((tag) => {
                        tags[tag] = true;
                    });
                }

                if (stream.location && stream.location.name && stream.location.name.length > 0) {
                    locations[stream.location.name] = true;
                }
            });

            Object.keys(markers).forEach((key) => {
                autocomplete.markers.push({ value: key });
            });
            Object.keys(tags).forEach((key) => {
                autocomplete.tags.push({ value: key });
            });
            Object.keys(locations).forEach((key) => {
                autocomplete.locations.push({ value: key });
            });

            return autocomplete;
        },
    },
    actions   : {
        fetchStreams ({ commit, state }) {
            state.isFetchingStreams = true;
            httpClient
                .get(GETTING_STREAMS_URL, {
                    params: {
                        page           : state.pageNumberOfStreams,
                        groupType      : state.groupType,
                        sortType       : state.sortType,
                        onlyAvailable  : state.isShownAvailableStreams,
                        searchingValues: state.searchingValues,
                    },
                })
                .then((res) => {
                    commit('setStreams', { streams: res.data });
                    state.isFetchingStreams = false;
                });
        },
        fetchStreamsStatuses ({ commit, state }) {
            httpClient
                .get(GETTING_STREAMS_URL, {
                    params: {
                        page           : state.pageNumberOfStreams,
                        groupType      : state.groupType,
                        sortType       : state.sortType,
                        isOnlyAvailable: state.isShownAvailableStreams,
                        searchingValues: state.searchingValues,
                    },
                })
                .then((res) => {
                    commit('setStreamsStatuses', { streams: res.data });
                });
        },
        updateStreams ({ commit, state }, payload) {
            commit('setStreams', { streams: payload });
            state.isFetchingStreams = false;
        },
        updateStreamsStatuses ({ dispatch }, payload) {
            dispatch('fetchStreamsStatuses', payload);
        },
        groupStreams ({ commit }, payload) {
            commit('setGroupType', payload);
            saveToLocalStorage({
                key  : 'groupType',
                value: payload,
                page : PAGES.ALL_STREAMS,
            });
        },
        searchStreams ({ commit }, payload) {
            commit('setSearchingValues', payload);
            saveToLocalStorage({
                key  : 'searchingValues',
                value: payload,
                page : PAGES.ALL_STREAMS,
            });
        },
        changeViewMode ({ commit }, payload) {
            commit('setViewModeKey', payload);
            saveToLocalStorage({
                key  : 'viewModeKey',
                value: payload,
                page : PAGES.ALL_STREAMS,
            });
        },
        toggleShowingAvailable ({ commit }, payload) {
            commit('setShowingAvailableState', payload);
            saveToLocalStorage({
                key  : 'isShownAvailableStreams',
                value: payload,
                page : PAGES.ALL_STREAMS,
            });
        },

        getStreamsPageSettingFromLocalStorage ({ commit }) {
            const streamsPageSettings = JSON.parse(localStorage.getItem(PAGES.ALL_STREAMS));

            if (streamsPageSettings) {
                [
                    { name: 'setSearchingValues', property: 'searchingValues' },
                    { name: 'setViewModeKey', property: 'viewModeKey' },
                    { name: 'setShowingAvailableState', property: 'isShownAvailableStreams' },
                    { name: 'setGroupType', property: 'groupType' },
                ].forEach((item) => {
                    if (streamsPageSettings[item.property]) {
                        commit(item.name, streamsPageSettings[item.property]);
                    }
                });
            }
        },
    },
};
