<template>
    <div class="layout">
        <div class="layout-header"
             ref="layoutHeader"
             v-if="isShowHeader">
            <ycc-alert class="layout-warning"
                       v-if="shownWarnings.length > 0"
                       :name="WARNING">
                <div class="layout-warning_content"
                     v-for="(warning, index) in shownWarnings"
                     :key="index">
                    <div v-html="warning.message"></div>
                    <button class="btn-ycc-clean-big"
                            v-if="!warning.strict"
                            type="button"
                            @click="hideWarning(warning.code)">
                    </button>
                </div>
            </ycc-alert>

            <ycc-alert v-if="isVisibleLicenseAlert"
                       :name="DANGER">
                <div class="validation validation-error">
                    <span class="validation_icon icon-ycc"></span>
                    <div class="validation_content">
                        {{ `${licenseStateTitle} ${licenseStateDescription}` }}
                        <router-link class="btn-ycc-link"
                                     :to="{name: PAGES.LICENSE}">
                            <span v-translate>Upload a license</span>
                        </router-link>
                        <span v-translate>for unlock.</span>
                    </div>
                </div>
            </ycc-alert>

            <ycc-header @logout="logout"
                        @toggled-aside="toggleAside">
            </ycc-header>
        </div>

        <div class="layout-main"
             :class="[layoutMainClasses, {'visible': hasAside}]">
            <div class="layout-aside"
                 :style="layoutAsideStyle">
                <div class="aside"
                     :class="{open: isOpenAside}">
                    <div class="aside-content">
                        <!-- ASIDE -->
                        <ycc-toolbar :page="$route.name"
                                     :tabs="tabs"
                                     :is-read-only-profile="isReadOnlyProfile"
                                     :is-open-aside="isOpenAside">
                        </ycc-toolbar>
                    </div>
                </div>
            </div>

            <div class="layout-content"
                 :style="{'padding-top': `${headerHeight}px`}">
                <!-- CONTENT -->
                <slot name="content"></slot>
            </div>
        </div>

        <div class="loading-page"
             v-if="isEmptyRoute"
             :loading="isEmptyRoute"
             :element-loading-text="'Page loading...'"
             :element-loading-spinner="'el-icon-loading'"
             :element-loading-background="'#fff'">
        </div>
    </div>
</template>

<script>
import {
    MOVE_TIME,
    PANEL_SIZE,
}                          from '@/constants/main';
import PAGES               from '@/constants/pages';
import { warningsStorage } from '@/extends/storageServices';
import YccToolbar          from '@/components/ycc-toolbar/YccToolbar.vue';
import YccHeader           from '@/components/ycc-header/YccHeader.vue';
import YccAlert            from '@/components/ycc-alert/YccAlert.vue';
import {
    DANGER,
    WARNING,
}                          from '@/components/ycc-alert/names';
import YccValidationError  from '@/components/ycc-validation-error/YccValidationError.vue';

export default {
    name      : 'YccLayout',
    components: {
        YccValidationError,
        YccToolbar,
        YccHeader,
        YccAlert,
    },
    props     : {
        warnings           : Array,
        tabs               : Object,
        isCloseAsideOutside: Boolean,
        isFixContent       : Boolean,
        hasAside           : Boolean,
        isReadOnlyProfile  : Boolean,
        isAuthOrInitPage   : Boolean,
        isLicenseExpired   : Boolean,
        isEmptyRoute       : Boolean,
        isOpenAside        : Boolean,
    },
    data () {
        return {
            DANGER,
            WARNING,
            PAGES,
            asideHeight  : 0,
            headerHeight : PANEL_SIZE,
            isClosing    : false,
            isOpening    : false,
            isMinScreen  : false,
            shownWarnings: [],
        };
    },
    created () {
        this.closeAsideIfMinScreen();
        window.addEventListener('resize', () => {
            this.setAsideHeight();
            this.defineScreenSize();
            this.setHeaderHeight();
        });
        window.addEventListener('click', this.closeAside.bind(this));
    },
    mounted () {
        this.setAsideHeight();
        this.defineScreenSize();
        this.setHeaderHeight();
    },
    beforeDestroy () {
        this.closeAsideIfMinScreen();
    },
    watch   : {
        isVisibleLicenseAlert () {
            this.$nextTick(() => {
                this.setHeaderHeight();
            });
        },
        warnings (newValue) {
            warningsStorage.clearExpiredWarnings();

            const hiddenWarningCodes = warningsStorage.getWarnings();

            this.setShownWarnings(newValue.filter((warning) => !hiddenWarningCodes.includes(warning.code)));

            this.$nextTick(() => {
                this.setHeaderHeight();
            });
        },
        isShowHeader (newValue, oldValue) {
            if (newValue && !oldValue) {
                this.$nextTick(() => {
                    this.setHeaderHeight();
                });
            }
        },
    },
    computed: {
        layoutMainClasses () {
            return {
                open               : this.isOpenAside,
                closing            : this.isClosing,
                opening            : this.isOpening,
                'not-fixed-content': !this.isFixContent,
            };
        },
        layoutAsideStyle () {
            return {
                minHeight: `${PANEL_SIZE}px`,
                marginTop: `${this.headerHeight}px`,
                height   : `calc(100vh - ${this.headerHeight}px)`,
            };
        },
        isShowHeader () {
            return !this.isAuthOrInitPage && !this.isEmptyRoute;
        },
        isVisibleLicenseAlert () {
            return this.isLicenseExpired && !this.isAuthOrInitPage;
        },
        licenseStateTitle () {
            return this.$gettext('License is missing or expired.');
        },
        licenseStateDescription () {
            return this.$gettext('Addition streams and watching archive has been disabled.');
        },
    },
    methods : {
        toggleAside (event) {
            if (this.isOpenAside) {
                this.isClosing = true;
                setTimeout(() => {
                    this.isClosing = false;
                    this.changeAsideState(false);
                }, MOVE_TIME);
            }
            else {
                this.changeAsideState(true);
                this.isOpening = true;
                setTimeout(() => {
                    this.isOpening = false;
                }, MOVE_TIME);
            }
            event.stopPropagation();
        },
        closeAside () {
            if (this.isOpenAside && (this.isCloseAsideOutside || this.isMinScreen)) {
                this.isClosing = true;
                setTimeout(() => {
                    this.isClosing = false;
                    this.changeAsideState(false);
                }, MOVE_TIME);
            }
        },
        setAsideHeight () {
            this.asideHeight = document.documentElement.clientHeight;
        },
        setHeaderHeight () {
            this.headerHeight = this.$refs.layoutHeader ? this.$refs.layoutHeader.clientHeight : PANEL_SIZE;
            this.$emit('changed-header-height', { headerHeight: this.headerHeight });
        },
        defineScreenSize () {
            this.isMinScreen = (
                document.documentElement.clientHeight <= 600 || document.documentElement.clientWidth < 615
            );
        },
        closeAsideIfMinScreen () {
            if (this.isMinScreen) {
                this.changeAsideState(false);
            }
        },
        logout () {
            this.$emit('logout');
        },
        hideWarning (code) {
            warningsStorage.setWarning(code);
            this.setShownWarnings(this.shownWarnings.filter((warning) => warning.code !== code));
            this.$nextTick(() => {
                this.setHeaderHeight();
            });
        },
        setShownWarnings (warnings) {
            this.shownWarnings = warnings;
        },
        changeAsideState (state) {
            this.$emit('changed-aside-state', { state });
        },
    },
};
</script>
