import createHint from './createHint';

/* eslint-disable object-curly-newline */
// eslint-disable-next-line func-names
export default function ({ hint, hintId, position, isDark, maxWidth }) {
    const hintRenderContentId = 'hint-content';

    if (!window.hint) {
        const popup = document.createElement('div');
        popup.setAttribute('id', hintRenderContentId);
        document.body.appendChild(popup);

        window.hint = createHint({ hint, hintId, position, isDark, maxWidth }, hintRenderContentId);
    }
    return window.hint;
}
