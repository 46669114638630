<template>
    <div class="validation"
         :class="validationCssClass">
        <span class="validation_icon icon-ycc"></span>
        <div class="validation_content">
            {{ error }}
        </div>
    </div>
</template>

<script>
export default {
    name : 'YccValidationError',
    props: {
        type  : {
            type   : String,
            default: 'error',
        },
        error : String,
    },
    computed: {
        validationCssClass () {
            return `validation-${this.type}`;
        },
    },
};
</script>
