function PropertyError (property, message) {
    this.name = 'PropertyError';

    this.property = property;
    this.message  = message;

    if (Error.captureStackTrace) {
        Error.captureStackTrace(this, PropertyError);
    }
    else {
        this.stack = (new Error()).stack;
    }
}

export default PropertyError;
