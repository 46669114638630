import Vue from 'vue';

Vue.config.devtools = process.env.NODE_ENV === 'development';
Vue.config.productionTip = process.env.NODE_ENV === 'production';
/* eslint-disable-next-line */
Vue.config.errorHandler = function (err, vm, info) {
    let handler;
    let current = vm;
    if (vm.$options.errorHandler) {
        handler = vm.$options.errorHandler;
    }
    else {
        while (current.$parent) {
            current = current.$parent;
            /* eslint-disable-next-line */
            if (handler = current.$options.errorHandler) {
                break;
            }
        }
    }
    if (handler) {
        handler.call(current, err, vm, info);
    }
    else {
        /* eslint-disable-next-line */
        console.log(err);
    }
};
