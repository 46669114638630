export default {
    progress: {
        func: [
            { call: 'color', modifier: 'temp', argument: '#e6fc94' },
            { call: 'fail', modifier: 'temp', argument: '#6e0000' },
            { call: 'location', modifier: 'temp', argument: 'top' },
            {
                call    : 'transition',
                modifier: 'temp',
                argument: {
                    speed      : '1.5s',
                    opacity    : '0.6s',
                    termination: 400,
                },
            },
        ],
    },
};
