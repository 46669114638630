import StorageAdapter from '@/extends/StorageAdapter';

const destinationPageStorage = new StorageAdapter({
    itemName   : 'destinationPage',
    isPrimitive: false,
}, sessionStorage);

class WarningStorage extends StorageAdapter {
    constructor () {
        super({
            itemName   : 'warnings',
            isPrimitive: false,
        });

        this.keepingTime = 7 * 24 * 60 * 60 * 1000;

        if (!this.getItem()) {
            this.setItem({});
        }
        else {
            this.clearExpiredWarnings();
        }
    }

    setWarning (warningCode) {
        const warnings        = this.getItem();
        warnings[warningCode] = (new Date(Date.now() + this.keepingTime)).toISOString();
        this.setItem(warnings);
    }

    getWarnings () {
        return Object.keys(this.getItem());
    }

    static isExpired (warningCode, warnings) {
        return warnings[warningCode] <= (new Date()).toISOString();
    }

    clearExpiredWarnings () {
        const warnings = this.getItem() || {};

        Object.keys(warnings).forEach((warningCode) => {
            if (WarningStorage.isExpired(warningCode, warnings)) {
                delete warnings[warningCode];
            }
        });

        this.setItem(warnings);
    }
}

const warningsStorage = new WarningStorage();

export {
    destinationPageStorage,
    warningsStorage,
};
