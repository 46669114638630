import dayjs from 'dayjs';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

// Вычислить размеры фрагментов и пропусков
export function getFragmentWidth (start, end, duration) {
    return ((end - start) * 100) / duration;
}

export function getCoords (elem) {
    const box = elem.getBoundingClientRect();
    return {
        top   : box.top + window.pageYOffset,
        left  : box.left + window.pageXOffset,
        width : box.width,
        height: box.height,
    };
}

/* eslint-disable */
export function getElementUnderClientXY (elem, clientX, clientY) {
    const display      = elem.style.display || '';
    elem.style.display = 'none';

    let target = document.elementFromPoint(clientX, clientY);

    elem.style.display = display;

    if (!target || target === document) { // это бывает при выносе за границы окна
        target = document.body; // поправить значение, чтобы был именно элемент
    }

    return target;
}

export function getElemFromPoint (clientX, clientY) {
    return document.elementFromPoint(clientX, clientY);
}

export function formatDate (date, formatType = 'll', language = 'ru') {
    const formatsMap = {
        lll: 'D MMM YYYY, HH:mm',
        ll : 'D MMM YYYY',
        L  : 'DD.MM.YYYY',
        LL : 'D MMMM YYYY',
        LT : 'HH:mm',
    };
    const format     = formatsMap[formatType] || formatType;

    dayjs.locale(language);

    const monthLetters = dayjs(date).format('MMMM').split('');
    const month        = `${monthLetters[0].toUpperCase()}${monthLetters[1]}${monthLetters[2]}`;
    const DMMMYYYY     = `${dayjs(date).format('D')} ${month} ${dayjs(date).format('YYYY')}`;
    const MMMDD        = `${month} ${dayjs(date).format('DD')}`;
    const HHmmss       = dayjs(date, DATE_FORMAT).format('HH:mm:ss');

    switch (format) {
        case 'D MMM':
            return `${dayjs(date).format('D')} ${month}`;
        case 'MMM DD':
            return MMMDD;
        case 'DD MMM, HH:mm':
            return `${dayjs(date).format('D')} ${month}, ${dayjs(date, DATE_FORMAT).format('HH:mm')}`;
        case 'D MMM YYYY':
            return DMMMYYYY;
        case 'D MMM YYYY, HH:mm':
            return `${DMMMYYYY}, ${dayjs(date, DATE_FORMAT).format('HH:mm')}`;
        case 'HH:mm:ss, MMM DD':
            return `${HHmmss}, ${MMMDD}`;
        case 'HH:mm:ss, MMM DD, YYYY':
            return `${HHmmss}, ${MMMDD}, ${dayjs(date).format('YYYY')}`;
        default:
            return dayjs(date, DATE_FORMAT).format(format);
    }
}

export const convertDateToSeconds = (date) => {
    return new Date(date).getTime() / 1000;
};

export function getCookie (name) {
    let matches = document.cookie.match(new RegExp(
        '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)',
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie (name, value, options = {}) {
    options = {
        path: '/',
        ...options,
    };

    if (options.expires && options.expires.toUTCString) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += '; ' + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += '=' + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

export function deleteCookie (name) {
    setCookie(name, '', {
        'max-age': -1,
    });
}

export function getWrapHeight (selector) {
    const { clientHeight } = document.documentElement;
    const authWrapHeight   = document.querySelector(selector) ? document.querySelector(selector).clientHeight : 0;

    return authWrapHeight >= clientHeight ? 'auto' : '100%';
}

export function yccScrollTo (elem) {
    const top = elem.getBoundingClientRect().y;

    if (top !== 0) {
        window.scrollTo({
            top     : top + window.pageYOffset - 60,
            behavior: 'smooth',
        });
    }
}

/**
 * Получить порядковый номер символа в строке
 * @param str
 * @param symbol
 * @returns {null}
 */
export function getIndexSymbol (str, symbol) {
    let indexSymbol = null;

    for (let i = 0; i < str.length; i++) {
        if (str.charAt(i) === symbol) {
            indexSymbol = i;
        }
    }
    return indexSymbol;
}

function getChar (event, isNumber = false) {
    if (event.which === null) {
        if (event.keyCode < 32 || (isNumber && event.keyCode < 48)) {
            return null;
        }
        return String.fromCharCode(event.keyCode); // IE
    }

    if (event.which !== 0 && event.charCode !== 0) {
        if (event.which < 32 || (isNumber && event.which < 48)) {
            return null;
        }
        return String.fromCharCode(event.which); // остальные
    }

    return null; // специальная клавиша
}

export function validate (event, isNumber = false) {
    if (event.ctrlKey || event.altKey || event.metaKey) {
        return;
    }

    let chr = getChar(event, isNumber);

    if (chr === null) {
        return;
    }

    if (chr < '0' || chr > '9') {
        event.preventDefault();
    }
}

export function cloneObjDeep (obj) {
    return JSON.parse(JSON.stringify(obj));
}

export function getScrollWidth () {
    // Creating invisible container
    const outer                 = document.createElement('div');
    outer.style.visibility      = 'hidden';
    outer.style.overflow        = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
}

export function isChildElem ({ className, elem, modalId }) {
    function findElements (selector) {
        return document.querySelectorAll(selector);
    }

    if (className && elem.classList.contains(className)) {
        return true;
    }

    let selector = '';
    if (className.length > 0) {
        selector = `.${className}`;
    }
    const notClosingElements = findElements(`#${modalId} ${selector} *`);
    for (let i = 0; i < notClosingElements.length; i++) {
        if (elem === notClosingElements[i]) {
            return true;
        }
    }
    return false;
}

export function getSortingDirection (sortingType) {
    if (sortingType === 'desc') {
        return -1;
    }
    return 1;
}

export function showServerSwitcher () {
    app.serverSwitcher();
}
