<!--eslint-disable-->
<template>
    <div class="logo"
         :class="{'fix-height': isHeader}">
        <img :src="logo">
    </div>
</template>

<script>
import lightLogo from '../assets/images/logo-light.svg';
import darkLogo  from '../assets/images/logo-dark.svg';

const THEMES = {
    light: 'light',
    dark : 'dark',
};
const LOGOS  = {
    [THEMES.light]: lightLogo,
    [THEMES.dark] : darkLogo,
};

export default {
    name    : 'ycc-logo',
    props   : {
        theme   : { type: String, default: THEMES.light },
        isHeader: Boolean,
    },
    computed: {
        logo () {
            return this.$appLogo || LOGOS[this.theme];
        },
    },
};
</script>

<style lang="scss"
       scoped>
    .logo {
        display     : flex;
        align-items : center;
        width       : 100%;

        img {
            width : 100%;
        }

        &.fix-height {
            height : 100%;
            width  : auto;

            img {
                height : 100%;
                width  : auto;
            }
        }
    }
</style>
