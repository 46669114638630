import { interval } from 'rxjs';
import dayjs        from 'dayjs';
import {
    getDefaultPeriod,
    getDefaultPlaylist,
}                   from '@/store/player/lib';
import {
    DEFAULT_PLAYLIST_DURATION,
    EN,
    PLAYER_VIEW_MODES,
}                   from '@/constants/main';
import {
    convertDateToSeconds,
    formatDate,
    getCoords,
}                   from '@/extends/lib';
import {
    ANNOTATION_TYPES,
    RANGE_TYPES,
}                   from '@/components/ycc-player/constants';
import {
    getMarkerOptions,
    getNewMarkers,
    MARKER_LABEL_WIDTH,
    MS_IN_HOUR,
    MS_IN_MINUTE,
}                   from '@/components/ycc-player/extends/createMarkers';
import {
    getCurrentDateId,
    getProgressBarId,
    getThumbId,
}                   from '@/components/ycc-player/extends/componentIds';

const ZOOM_INDEX   = 1.5;
const HOUR_IN_MS   = 60 * 60 * 1000;
const MIN_DURATION = 5 * 60 * 1000;
const MAX_DURATION = 24 * HOUR_IN_MS;

export default class ProgressBarController {
    constructor ({ playerId, thumbWidth }) {
        // not changing
        this.playerId   = playerId;
        this.thumbWidth = thumbWidth || 18;

        // elements
        this.video       = null;
        this.progressBar = null;
        this.thumb       = null;

        // first level of changing
        this.archiveStatsPeriod = null;
        this.period             = getDefaultPeriod(); // all visible period
        this.ranges             = null;

        // second level of changing
        this.playingPeriod   = null; // { start: 0, end: 0 };
        this.currentInterval = null;

        // third level of changing
        this.playingTime   = 0; // or Date.now()  // playingTime
        this.thumbPosition = 0;

        this.tracking            = null;
        this.trackingObservable$ = interval(20);

        this.init();
    }

    init () {
        this.video       = ProgressBarController.getElem(this.playerId);
        this.progressBar = ProgressBarController.getElem(getProgressBarId(this.playerId));
        this.thumb       = ProgressBarController.getElem(getThumbId(this.playerId));
    }

    /* GETTERS */

    get duration () {
        return this.period.end - this.period.start;
    }

    get intervals () {
        // changing triggers: period and ranges
        const startSeconds  = convertDateToSeconds(this.period.start);
        const endSeconds    = convertDateToSeconds(this.period.end);
        const clipShift     = startSeconds;
        const intervalsTemp = [];
        const ranges        = this.ranges
            ? this.ranges
            : getDefaultPlaylist({
                start: this.period.start,
                end  : this.period.end,
            }).ranges;

        ranges.forEach((range, index, arr) => {
            const rangeStart   = convertDateToSeconds(range.date);
            const rangeEnd     = arr[index + 1] ? convertDateToSeconds(arr[index + 1].date) : endSeconds;
            const intervalType = ProgressBarController.getIntervalType({
                status            : range.status,
                isLastRange       : index === arr.length - 1,
                date              : range.date,
                archiveStatsPeriod: this.archiveStatsPeriod,
            });

            intervalsTemp.push({
                width    : this.getPXFromSeconds(rangeEnd - startSeconds)
                    - this.getPXFromSeconds(rangeStart - startSeconds),
                left     : this.getPXFromSeconds(rangeStart - startSeconds),
                type     : intervalType,
                start    : rangeStart - clipShift,
                end      : rangeEnd - clipShift,
                startDate: range.date,
                endDate  : arr[index + 1] ? arr[index + 1].date : this.period.end,
            });
        });
        return intervalsTemp;
    }

    get periodModifier () {
        return (this.duration - (this.duration / ZOOM_INDEX)) / 2;
    }

    get isZoomUpAllowed () {
        return this.duration > MIN_DURATION;
    }

    get isZoomDownAllowed () {
        return this.duration < MAX_DURATION;
    }

    get periodISO () {
        return {
            start: dayjs(this.period.start).toISOString(),
            end  : dayjs(this.period.end).toISOString(),
        };
    }

    /* END GETTERS */

    /* MAIN PUBLIC METHODS */

    seekHandler (intervalElem, clientX, annotationStartSeconds) {
        this.stopTracking();

        this.setCurrentInterval(intervalElem);

        return this.getPlaylistByIntervalType(intervalElem, clientX, annotationStartSeconds);
    }

    startTracking (isArchive) {
        this.tracking = this.trackingObservable$.subscribe(() => {
            let currentTime = Date.now();

            if (isArchive && this.video) {
                currentTime = ProgressBarController.getCurrentTimeInMS(this.video.currentTime)
                    + this.playingPeriod.start;
            }

            this.updatePlayingTime(currentTime);
        });
    }

    stopTracking () {
        if (this.tracking) {
            this.tracking.unsubscribe();
        }
    }

    getMarkers (lang = EN) {
        const possibleLabels = Math.ceil(this.getProgressBarWidth() / MARKER_LABEL_WIDTH);
        const hoursNumber    = Math.ceil((this.period.end - this.period.start) / MS_IN_HOUR);
        const minutesNumber  = Math.ceil((this.period.end - this.period.start) / MS_IN_MINUTE);
        const options        = getMarkerOptions({ hoursNumber, possibleLabels, minutesNumber });

        return getNewMarkers(options, this.period, this.secondsInPx(), lang);
    }

    getNewPlaylistPeriodAfterEnding () {
        this.stopTracking();

        return this.getRangeByMilliseconds(this.playingTime);
    }

    getCorrectedPlayingPeriodEnd (newSeconds, intervalRangeEnd) {
        const endMS              = newSeconds + DEFAULT_PLAYLIST_DURATION;
        const intervalRangeEndMS = intervalRangeEnd;
        const result             = endMS < intervalRangeEndMS ? endMS : intervalRangeEndMS;

        return result * 1000 + this.period.start;
    }

    getRangeByMilliseconds (/* playingTime */) {
        const findNextRangeIndex = (startTimeMS) => {
            return this.intervals.findIndex((intervalRange) => {
                const start = Math.trunc(intervalRange.start * 1000 + this.period.start);
                const end   = Math.trunc(intervalRange.end * 1000 + this.period.start);

                return startTimeMS >= start && startTimeMS < end;
            });
        };

        const currentTime = this.playingPeriod.end; /* playingTime; */
        const intervalIndex   = findNextRangeIndex(currentTime);
        const currentInterval = this.intervals[intervalIndex];
        let currentPeriod     = { type: RANGE_TYPES.LIVE };

        if (currentInterval.type === RANGE_TYPES.GAP) {
            currentPeriod = {
                ...this.getNextPeriodAfterGap(intervalIndex + 1),
            };
        }
        else if (currentInterval.type !== RANGE_TYPES.LIVE) {
            currentPeriod = {
                start: currentTime,
                end  : this.getCorrectedPlayingPeriodEnd(
                    (currentTime - this.period.start) / 1000, currentInterval.end,
                ),
                type : RANGE_TYPES.FRAGMENT,
            };
        }

        return currentPeriod;
    }

    /* end MAIN PUBLIC METHODS */

    /* PUBLIC SETTERS */

    setPeriod (period) {
        this.period = period;
    }

    setRanges (ranges) {
        this.ranges = ranges;
    }

    setPlayingPeriod (playingPeriod) {
        this.playingPeriod = playingPeriod;
    }

    setArchiveStatsPeriod (stats) {
        this.archiveStatsPeriod = stats;
    }

    setCurrentInterval (intervalElem) {
        this.currentInterval = intervalElem;
    }

    /* end PUBLIC SETTERS */

    secondsInPx () {
        return this.duration / (this.getProgressBarWidth() * 1000);
    }

    getPXFromSeconds (seconds) {
        return seconds / this.secondsInPx();
    }

    getSecondsFromPX (px) {
        return px * this.secondsInPx();
    }

    static getIntervalType (
        {
            status,
            isLastRange,
            date,
            archiveStatsPeriod,
        }) {
        const liveTime   = archiveStatsPeriod ? archiveStatsPeriod.endTime : dayjs(new Date()).toISOString();
        let intervalType = RANGE_TYPES.GAP;

        if (isLastRange && liveTime <= date && !status) {
            intervalType = RANGE_TYPES.LIVE;
        }

        return status ? RANGE_TYPES.FRAGMENT : intervalType;
    }

    setDefaultLiveThumbPos () {
        // set default position for current time in ios safari
        setTimeout(() => {
            this.updatePlayingTime(Date.now());
        }, 100);
    }

    updateThumbPos (currentTime, periodStart) {
        const thumbLeftPosition = this.getPXFromSeconds((currentTime - periodStart) / 1000);

        this.changeThumbStyle(thumbLeftPosition);
        this.keepThumbPosition(thumbLeftPosition);
    }

    changeThumbStyle (thumbLeftPosition) {
        if (this.thumb && Math.trunc(thumbLeftPosition) !== this.thumbPosition) {
            this.thumb.style.transform = `translateX(${thumbLeftPosition - (this.thumbWidth / 2)}px)`;
        }
    }

    /* BRING */
    getPeriodForZoomUpByPointer () {
        const playingTime = this.playingTime || dayjs(new Date()).valueOf();

        return this.getPeriodForZoomUp(playingTime);
    }

    getPeriodForZoomUp (playingTime) {
        if (this.isZoomUpAllowed) {
            return {
                start: this.period.start + this.getStartModifier(playingTime),
                end  : this.period.end - this.getEndModifier(playingTime),
            };
        }

        return false;
    }

    /* put away */
    getPeriodForZoomDownByPointer () {
        const playingTime = this.playingTime || dayjs(new Date()).valueOf();

        return this.getPeriodForZoomDown(playingTime);
    }

    getPeriodForZoomDown (playingTime) {
        const currentTime = dayjs(new Date()).valueOf();

        if (this.isZoomDownAllowed) {
            return {
                start: this.period.start - this.getStartModifier(playingTime),
                end  : this.period.end >= currentTime + (3 * HOUR_IN_MS)
                    ? this.period.end : this.period.end + this.getEndModifier(playingTime),
            };
        }

        return false;
    }

    getPeriodForMoveLeft () {
        return {
            start: this.period.start - this.periodModifier,
            end  : this.period.end - this.periodModifier,
        };
    }

    getPeriodForMoveRight () {
        return {
            start: this.period.start + this.periodModifier,
            end  : this.period.end + this.periodModifier,
        };
    }

    getPeriodForGoToCenter () {
        return {
            start: this.playingTime - (this.duration / 2),
            end  : this.playingTime + (this.duration / 2),
        };
    }

    getStartModifier (playingTime) {
        return ((playingTime - this.period.start) / this.duration) * (this.periodModifier * 2);
    }

    getEndModifier (playingTime) {
        return ((this.period.end - playingTime) / this.duration) * (this.periodModifier * 2);
    }

    getAnnotationsByTypes (annotations) {
        const annotationsByType = {};

        if (annotations.length === 0) {
            annotationsByType.empty = [];
        }
        else {
            annotations.forEach((annotation) => {
                if (annotationsByType[annotation.type]) {
                    annotationsByType[annotation.type].push(this.adaptAnnotation(annotation));
                }
                else {
                    annotationsByType[annotation.type] = [this.adaptAnnotation(annotation)];
                }
            });
        }

        return annotationsByType;
    }

    adaptAnnotation (annotation) {
        const startSeconds    = convertDateToSeconds(this.period.start);
        const annotationStart = convertDateToSeconds(annotation.time);
        const annotationEnd   = convertDateToSeconds(annotation.timeEnd);

        return {
            ...annotation,
            startSeconds: annotationStart,
            width: this.getPXFromSeconds(annotationEnd - startSeconds)
                - this.getPXFromSeconds(annotationStart - startSeconds),
            left : this.getPXFromSeconds(annotationStart - startSeconds),
            color: ProgressBarController.getAnnotationColorByType(annotation.type),
        };
    }

    static getAnnotationColorByType (type) {
        switch (type) {
            case ANNOTATION_TYPES.EVENT:
                return '#ece283';
            default:
                return '#d466d4';
        }
    }

    /* PRIVATE METHODS */

    getProgressBarWidth () {
        return this.progressBar ? getCoords(this.progressBar).width : 0;
    }

    updatePlayingTime (time) {
        this.setPlayingTime(time);
        this.innerPlayingTime(time);
        this.updateThumbPos(time, this.period.start);
    }

    setPlayingTime (time) {
        this.playingTime = time;
    }

    keepThumbPosition (thumbPosition) {
        this.thumbPosition = thumbPosition;
    }

    getPlaylistByIntervalType (intervalElem, clientX, annotationStartSeconds) {
        let newInterval   = this.intervals[intervalElem.dataset.index];
        let playingPeriod = null;
        let newViewMode   = PLAYER_VIEW_MODES.LIVE;

        if (intervalElem.dataset.type === RANGE_TYPES.GAP && this.isLastElem(intervalElem)) {
            return false;
        }

        if (intervalElem.dataset.type !== RANGE_TYPES.LIVE) {
            let newCurrentSeconds = 0;

            if (intervalElem.dataset.type === RANGE_TYPES.GAP) {
                newInterval       = this.intervals[Number(intervalElem.dataset.index) + 1];
                newCurrentSeconds = newInterval.start;
            }
            else if (intervalElem.dataset.type === RANGE_TYPES.FRAGMENT) {
                newCurrentSeconds = annotationStartSeconds
                    ? annotationStartSeconds - (this.period.start / 1000) - 10
                    : this.getSecondsFromPos(clientX);
            }

            playingPeriod = {
                start: (newCurrentSeconds * 1000) + this.period.start,
                end  : this.getCorrectedPlayingPeriodEnd(newCurrentSeconds, newInterval.end),
            };

            newViewMode = PLAYER_VIEW_MODES.ARCHIVE;
        }

        return { newInterval, playingPeriod, newViewMode };
    }

    isLastElem (elem) {
        return this.intervals.length === Number(elem.dataset.index) + 1;
    }

    getSecondsFromPos (clientX) {
        const leftShift = this.progressBar ? this.progressBar.getBoundingClientRect().left : 0;
        return this.getSecondsFromPX(clientX - leftShift);
    }

    innerPlayingTime (time) {
        const currentTimeElem = ProgressBarController.getElem(getCurrentDateId(this.playerId));

        if (currentTimeElem) {
            currentTimeElem.innerHTML = formatDate(time, 'HH:mm:ss, D MMMM');
        }
    }

    getNextPeriodAfterGap (index) {
        return this.intervals[index]
            ? {
                start: this.intervals[index].start * 1000 + this.period.start,
                end  : this.getCorrectedPlayingPeriodEnd(
                    this.intervals[index].start,
                    this.intervals[index].end,
                ),
                type : this.intervals[index].type,
            }
            : null;
    }

    /* end PRIVATE METHODS */

    static getElem (id) {
        return document.getElementById(id);
    }

    static getCurrentTimeInMS (videoCurrentTime) {
        return videoCurrentTime * 1000;
    }
}
