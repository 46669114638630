export const BASE_STREAM_URL     = '/v1/stream';
export const GETTING_STREAMS_URL = '/v1/streams';
export const PAUSE_STREAM_URL    = `${BASE_STREAM_URL}/pause`;
export const RESUME_STREAM_URL   = `${BASE_STREAM_URL}/resume`;
export const PROBE_STREAM_URL    = `${BASE_STREAM_URL}/probe`;
export const PREVIEW_STREAM_URL  = `${BASE_STREAM_URL}/preview`;

export function getArchiveUrl (streamUid) {
    return `${BASE_STREAM_URL}/${streamUid}/archive/index.m3u8`;
}

export function getRangesUrl (streamUid) {
    return `${BASE_STREAM_URL}/${streamUid}/archive/ranges`;
}

export function getArchiveStatsUrl (streamUid) {
    return `${BASE_STREAM_URL}/${streamUid}/archive/stats`;
}

export function getAvailableDaysUrl (streamUid) {
    return `${BASE_STREAM_URL}/${streamUid}/archive/available-days`;
}

export function getDownloadArchiveUrl ({ streamUid }) {
    return `${BASE_STREAM_URL}/${streamUid}/archive/download`;
}

export function getStreamPermissionsUrl (streamUid) {
    return `${BASE_STREAM_URL}/${streamUid}/permissions`;
}

export function getStreamLogsUrl (streamUid) {
    return `${BASE_STREAM_URL}/${streamUid}/logs`;
}

export function getStreamShareUrl (streamUid) {
    return `${BASE_STREAM_URL}/${streamUid}/share`;
}

export function getStreamInfoUrl (streamUid, isLiveOnDemand = false) {
    return isLiveOnDemand
        ? `${BASE_STREAM_URL}/${streamUid}?extend_lease=true`
        : `${BASE_STREAM_URL}/${streamUid}`;
}


export const SYSTEM_SETTINGS_URL = '/v1/system/internal/ui/settings';
export const SYSTEM_SEARCH       = '/v1/system/internal/ui/search';
export const UNLOCK_URL          = '/v1/system/unlock';
export const HEALTH_URL          = '/v1/system/health';
export const LICENSE_URL         = '/v1/system/license';
export const SERVER_DEBUG_URL    = '/v1/system/logs';
export const SYSTEM_STATS_URL    = '/v1/system/stats';


export const SIGN_IN_URL  = '/v1/sign-in';
export const SIGN_OUT_URL = '/v1/sign-out';


export const USER_URL  = '/v1/user';
export const USERS_URL = '/v1/users';

export function getBlockUserUrl (login) {
    return `${USER_URL}/${login}/block`;
}

export function getUserPermissionsUrl (login) {
    return `${USER_URL}/${login}/permissions`;
}

export function getUnblockUserUrl (login) {
    return `${USER_URL}/${login}/unblock`;
}

export function getSessionsUserUrl (login) {
    return `${USER_URL}/${login}/sessions`;
}

export function getSessionUserUrl (login, tokenId) {
    return `${USER_URL}/${login}/session/${tokenId}`;
}


export const PROFILE_TOKENS_URL = '/v1/tokens';
export const PROFILE_TOKEN_URL  = '/v1/token';

export function getDeleteTokenUrl (tokenId) {
    return `${PROFILE_TOKEN_URL}/${tokenId}`;
}


export const STATS_URL       = '/v1/admin/stats';
export const CONFIG_URL      = '/v1/admin/configuration';
export const AUDIT_LOGS_URL  = '/v1/admin/audit-logs';
export const ANNOTATIONS_URL = '/v1/annotations';
export const MOSAIC_URL      = '/v1/mosaic';
export const ALL_MOSAICS_URL = '/v1/mosaics';

export const API_VERSION_CHECK = 'https://api.yucca.app/v1/version-check';


export const SESSIONS_URL           = '/v1/sessions';
export const TERMINATE_SESSIONS_URL = `${SESSIONS_URL}/terminate`;
export const getSessionUrlById      = (id) => `/v1/session/${id}`;

export const TUNNEL_START_URL  = '/v1/tunnel/start';
export const TUNNEL_STOP_URL   = '/v1/tunnel/stop';
export const TUNNEL_STATUS_URL = '/v1/tunnel/status';


export const CLUSTER_NODES_URL      = '/v1/system/cluster/nodes';
export const INTERNAL_NODES_URL     = '/v1/system/internal/ui/nodes';
export const getClusterNodeUrl      = (nodeId) => `/v1/system/cluster/node/${nodeId}`;
export const getClusterNodeDrainUrl = (nodeId) => `/v1/system/cluster/node/${nodeId}/drain`;
export const getClusterNodeDeleteUrl = (nodeId) => `/v1/system/cluster/node/${nodeId}`;

export const PASSWORD_SEND_RESET_URL = '/v1/password/send-reset';
export const PASSWORD_RESET_URL      = '/v1/password/reset';
