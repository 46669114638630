<template>
    <div class="header">
        <div class="layout-header-aside-item"
             @click="toggleAside">
            <div class="aside-item_content">
                <div class="aside-item_icon">
                    <span class="icon-ycc">
                        <i class="material-icons">menu</i>
                    </span>
                </div>
            </div>
        </div>

        <div class="header-nav">
            <router-link class="header-item header-logo link-ycc"
                         :to="{name: PAGES.ALL_STREAMS}">
                <div class="header-logo"
                     :class="{'ycc-logo-height': !hasAppLogo}">
                    <ycc-logo :is-header="true"></ycc-logo>
                </div>
            </router-link>

            <template v-if="!isReadOnly">
                <ycc-hint class="header-item-hint"
                          :hint-id="'addStream'"
                          :hint="addingStreamTitle"
                          :position="'bottom'">
                    <template #opener>
                        <router-link class="header-item link-ycc"
                                     :to="{name: PAGES.ADD_STREAM}">
                            <div class="header-item_icon">
                                <span class="icon-ycc"><i class="material-icons">add</i></span>
                            </div>
                        </router-link>
                    </template>
                </ycc-hint>
            </template>
        </div>

        <div class="header-action">
            <div class="header-action_stream"
                 v-if="isStreamPage">
                <ycc-hint class="header-item-hint header-stream-name-wrapper"
                          :hint-id="'streamView'"
                          :hint="streamName"
                          :is-opener-equal-content="true"
                          :position="'bottom'">
                    <div class="header-item"
                         slot="opener"
                         :class="{'active': isActivePage(PAGES.STREAM_VIEW)}">
                        <router-link class="header-stream-name text-ycc-bold"
                                     :to="{name: PAGES.STREAM_VIEW, params: streamRouterParams}">
                            {{ streamName }}
                        </router-link>
                    </div>
                </ycc-hint>

                <ycc-hint class="header-item-hint"
                          :hint-id="'streamInfo'"
                          :hint="streamSettingsTitle"
                          :position="'bottom'">
                    <router-link class="header-item"
                                 slot="opener"
                                 :class="{'active': isStreamInfoPage}"
                                 :to="{name: PAGES.STREAM_INFO, params: streamRouterParams}">
                            <span class="icon-ycc stream-info_settings">
                                <i class="material-icons">info</i>
                            </span>
                    </router-link>
                </ycc-hint>
            </div>

            <div class="header-action_stream"
                 v-else-if="isMosaicPage">
                <ycc-hint class="header-item-hint header-stream-name-wrapper"
                          :hint-id="'mosaicWatch'"
                          :hint="mosaicName"
                          :is-opener-equal-content="true"
                          :position="'bottom'">
                    <div class="header-item"
                         slot="opener"
                         :class="{'active': isActivePage(PAGES.WATCH_MOSAIC)}">
                        <router-link class="header-stream-name text-ycc-bold"
                                     :to="{name: PAGES.WATCH_MOSAIC, params: mosaicRouterParams}">
                            {{ mosaicName }}
                        </router-link>
                    </div>
                </ycc-hint>

                <ycc-hint class="header-item-hint"
                          v-if="mosaic && mosaic.meta && mosaic.meta.canAdmin"
                          :hint-id="'mosaicInfo'"
                          :hint="mosaicSettingsTitle"
                          :position="'bottom'">
                    <router-link class="header-item"
                                 slot="opener"
                                 :class="{'active': isActivePage(PAGES.EDIT_MOSAIC)}"
                                 :to="{name: PAGES.EDIT_MOSAIC, params: mosaicRouterParams}">
                            <span class="icon-ycc stream-info_settings">
                                <i class="material-icons">settings</i>
                            </span>
                    </router-link>
                </ycc-hint>
            </div>

            <div class="header-action_streams"
                 v-else-if="isAllStreamsPage">
                <ycc-hint class="header-item-hint"
                          :hint-id="'grouping'"
                          :hint="getGroupLabel(groupType)"
                          :position="'bottom'">
                    <span class="header-item"
                          slot="opener"
                          :class="{'text-ycc-active': groupType === GROUP_TYPES.TAG.key}"
                          v-if="groupType"
                          @click="groupStreams()">
                        <i class="material-icons">{{ GROUP_TYPES[groupType].icon }}</i>
                    </span>
                </ycc-hint>

                <ycc-hint class="header-item-hint"
                          :hint-id="'viewMode'"
                          :hint="viewModeTitle"
                          :position="'bottom'">
                    <span class="header-item"
                          slot="opener"
                          @click="changeViewMode()">
                        <i class="material-icons">{{ VIEW_MODES[viewModeKey].icon }}</i>
                    </span>
                </ycc-hint>

                <ycc-hint class="header-item-hint"
                          :hint-id="'availabilityStreams'"
                          :hint="availableStreamsInfo.title"
                          :position="'bottom'">
                    <span class="header-item"
                          slot="opener"
                          @click="toggleShowingAvailable()">
                        <!--Show only available-->
                        <span class="icon-ycc"
                              :class="{'text-ycc-success': isShownAvailableStreams}">
                            <i class="material-icons">{{ availableStreamsInfo.icon }}</i>
                        </span>
                    </span>
                </ycc-hint>
            </div>

            <div class="header-action_stream"
                 v-else>
                <ycc-hint class="header-item-hint"
                          :hint-id="'pageTitle'"
                          :hint="pageTitle"
                          :position="'bottom'">
                    <div class="header-page-title"
                         slot="opener">
                        {{ pageTitle }}
                    </div>
                </ycc-hint>
            </div>
        </div>

        <ycc-header-menu :version="version"
                         :is-admin="isAdmin"
                         :id="`menu-${$language.current}`"
                         :documentation-link="$yccConfig.documentation"
                         :isShowServerSwitcher="isShowServerSwitcher"
                         @logout="logout">
        </ycc-header-menu>
    </div>
</template>

<script>
import {
    mapGetters,
    mapState,
}                    from 'vuex';
import YccHint       from '@/components/ycc-hint/YccHint.vue';
import PAGES         from '@/constants/pages';
import pageNames     from '@/constants/pageNames';
import {
    GROUP_TYPES,
    GROUP_TYPES_KEYS,
    VIEW_MODES,
    VIEW_MODES_KEYS,
}                    from '@/constants/main';
import YccHeaderMenu from './YccHeaderMenu.vue';
import YccLogo       from '../YccLogo.vue';

export default {
    name      : 'YccHeader',
    components: {
        YccLogo,
        YccHeaderMenu,
        YccHint,
    },
    data () {
        return {
            GROUP_TYPES,
            VIEW_MODES,
            PAGES,
            streamName: '',
            mosaicName: '',
        };
    },
    watch   : {
        stream: {
            handler (newStream) {
                if (newStream) {
                    this.setStreamName(newStream.name);
                }
            },
            immediate: true,
        },
        mosaic: {
            handler (newMosaic) {
                if (newMosaic) {
                    this.setMosaicName(newMosaic.name);
                }
            },
            immediate: true,
        },
    },
    computed: {
        ...mapState({
            groupType              : (state) => state.streams.groupType,
            viewModeKey            : (state) => state.streams.viewModeKey,
            isShownAvailableStreams: (state) => state.streams.isShownAvailableStreams,
            stream                 : (state) => state.streamStore.currentStream,
            mosaic                 : (state) => state.mosaic.currentMosaic,
            version                : (state) => state.version,
            isShowServerSwitcher   : (state) => state.isShowServerSwitcher,
        }),
        ...mapGetters({
            isAdmin   : 'profile/isAdminProfile',
            isReadOnly: 'profile/isReadOnlyProfile',
        }),
        pageTitle () {
            if (this.$route.path.indexOf('administration') >= 0) {
                return '';
            }
            return pageNames(this.$gettext)[this.$route.name] || '';
        },
        addingStreamTitle () {
            return this.$gettext('Add Stream');
        },
        isStreamInfoPage () {
            return this.isActivePage(PAGES.STREAM_INFO)
                || this.isActivePage(PAGES.STREAM_SETTING)
                || this.isActivePage(PAGES.STREAM_DEBUG)
                || this.isActivePage(PAGES.STREAM_PERMISSION);
        },
        isStreamPage () {
            return this.isActivePage(PAGES.STREAM_VIEW)
                || this.isStreamInfoPage;
        },
        isMosaicPage () {
            return this.isActivePage(PAGES.WATCH_MOSAIC)
                || this.isActivePage(PAGES.EDIT_MOSAIC);
        },
        isAllStreamsPage () {
            return this.$route.name === PAGES.ALL_STREAMS;
        },
        streamRouterParams () {
            return this.stream ? {
                id  : this.stream.id,
                name: this.stream.name,
            } : {};
        },
        mosaicRouterParams () {
            return this.stream ? {
                id  : this.mosaic.id,
                name: this.mosaic.name,
            } : {};
        },
        viewModeTitle () {
            return this.$gettext('View mode');
        },
        streamSettingsTitle () {
            const translated = this.$gettext('Setting of "%{name}" stream');
            return this.$gettextInterpolate(translated, { name: this.streamName });
        },
        mosaicSettingsTitle () {
            const translated = this.$gettext('Setting of "%{name}" mosaic');
            return this.$gettextInterpolate(translated, { name: this.mosaicName });
        },
        availableStreamsInfo () {
            return {
                icon : this.isShownAvailableStreams ? 'radio_button_checked' : 'radio_button_unchecked',
                title: this.isShownAvailableStreams ? this.$gettext('Only available') : this.$gettext('All streams'),
            };
        },
        hasAppLogo () {
            return !!this.$appLogo;
        },
    },
    methods : {
        isActivePage (page) {
            return page === this.$route.name;
        },
        /* for All Streams Page */
        groupStreams (key = null) {
            let value = key;

            if (!key) {
                value = this.getNextType();
            }
            this.$store.dispatch('streams/groupStreams', value);
        },
        changeViewMode (key = null) {
            let value = key;

            if (!key) {
                value = this.getNextMode();
            }
            this.$store.dispatch('streams/changeViewMode', value);
        },
        getNextMode () {
            if (this.viewModeKey === VIEW_MODES_KEYS.GRID) {
                return VIEW_MODES_KEYS.TABLE;
            }
            return VIEW_MODES_KEYS.GRID;
        },
        getNextType () {
            if (this.groupType === GROUP_TYPES_KEYS.TAG) {
                return GROUP_TYPES_KEYS.NO_GROUP;
            }
            return GROUP_TYPES_KEYS.TAG;
        },
        toggleShowingAvailable () {
            this.$store.dispatch('streams/toggleShowingAvailable', !this.isShownAvailableStreams);
        },
        getGroupLabel (groupType) {
            const types = {
                [GROUP_TYPES_KEYS.TAG]     : this.$gettext('Grouped by tag'),
                [GROUP_TYPES_KEYS.NO_GROUP]: this.$gettext('No grouped'),
            };
            return types[groupType];
        },
        logout () {
            this.$emit('logout');
        },
        toggleAside (event) {
            this.$emit('toggled-aside', event);
        },
        setStreamName (name) {
            this.streamName = name;
        },
        setMosaicName (name) {
            this.mosaicName = name;
        },
    },
};
</script>
