<template>
    <div class="hint-box-ycc_opener"
         :class="{'with-ellipsis': isOpenerEqualContent}"
         ref="opener"
         :id="hintId"
         @mouseenter="openHint"
         @mouseleave="closeHint">
        <slot name="opener">
        </slot>
    </div>
</template>

<script>
import * as POSITIONS from '@/constants/positionConstants';

export default {
    name   : 'YccHint',
    props  : {
        hintId              : {
            type   : [String, Number],
            default: 'hint',
        },
        position            : {
            type   : String,
            default: POSITIONS.TOP,
        },
        hint                : String,
        isDark              : { type: Boolean, default: true },
        isOpenerEqualContent: Boolean,
        maxWidth            : [String, Number],
    },
    methods: {
        openHint () {
            window.bus.$emit('opened-hint', {
                props     : {
                    hintId  : this.hintId,
                    position: this.position,
                    hint    : this.hint,
                    isDark  : this.isDark,
                    maxWidth: this.maxWidth,
                },
                openerElem: this.$refs.opener,
            });
        },
        closeHint () {
            window.bus.$emit('closed-hint');
        },
    },
};
</script>

<style lang="scss">
@use "../../scss/components/popup";

.hint-box-ycc_opener .with-ellipsis {
    max-width : 100%;
}
</style>
