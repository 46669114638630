import Vue                from 'vue';
import VueProgressBar     from 'vue-progressbar';
import { Loading }        from 'element-ui';
import progressBarOptions from '../constants/progressBarOptions';
import Datepicker         from '../components/ycc-datepicker/datepicker';

Vue.use(VueProgressBar, progressBarOptions);
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
Vue.directive('scroll', {
    inserted (el, binding) {
        const f = (evt) => {
            if (binding.value(evt, el)) {
                window.removeEventListener('scroll', f);
            }
        };
        window.addEventListener('scroll', f);
    },
});
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('datepicker', Datepicker);
