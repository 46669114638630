/* eslint-disable import/no-cycle */

import Vue                        from 'vue';
import Vuex                       from 'vuex';
import router                     from '@/router';
import httpClient                 from '@/config/httpClient';
import PAGES                      from '@/constants/pages';
import {
    PANEL_SIZE,
    FEATURES,
}                                 from '@/constants/main';
import {
    HEALTH_URL,
    SIGN_IN_URL,
    SIGN_OUT_URL,
    SYSTEM_SETTINGS_URL,
    UNLOCK_URL,
}                                 from '@/constants/api';
import { CREDENTIALS_ERR }        from '@/constants/errorTypes';
import { getCookie }              from '@/extends/lib';
import {
    isMediaAtLeast720,
    isMobile,
}                                 from '@/extends/browserEnv';
import { destinationPageStorage } from '@/extends/storageServices';
import { saveToLocalStorage }     from './utilities';
import player                     from './player/player';
import profile                    from './profile';
import admin                      from './admin';
import streamStore                from './stream';
import license                    from './license';
import mosaic                     from './mosaic';
import streams                    from './streams';
import tunnel                     from './tunnel';
import cluster                    from './cluster';
import auth                       from './auth';

Vue.use(Vuex);
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
export default new Vuex.Store({
    modules  : {
        admin,
        player,
        profile,
        streamStore,
        license,
        mosaic,
        streams,
        tunnel,
        cluster,
        auth,
    },
    state    : {
        version: null,
        config : null,

        // Auth
        isAuthLoading  : false,
        isAdminCreating: false,

        // for LocalStorage
        isFixContent: true,
        isOpenAside : false,

        // GENERAL для всех страниц
        tabs                : null,
        headerHeight        : PANEL_SIZE,
        errors              : {},
        notice              : {},
        healthWarnings      : [],
        isMediaAtLeast720   : isMediaAtLeast720(),
        isCardMode          : isMobile && !isMediaAtLeast720,
        isShowServerSwitcher: false,
        isNotFoundPage      : false,
    },
    mutations: {
        // Auth Page
        setVersion (state, { version }) {
            state.version = version;
        },
        setHealthWarnings (state, { healthWarnings }) {
            state.healthWarnings = healthWarnings;
        },
        setAuthState (state, { value }) {
            state.isAuthLoading = value;
        },

        // General
        setFoundingPageState (state) {
            state.isNotFoundPage = !state.isNotFoundPage;
        },
        setError (state, { name, error }) {
            state.errors[name] = error;
            state.errors       = { ...state.errors };
        },
        deleteError (state, { name }) {
            if (state.errors[name]) {
                delete state.errors[name];
            }
            state.errors = { ...state.errors };
        },
        setCreatingAdminState (state, { value }) {
            state.isAdminCreating = value;
        },
        setAsideState (state, payload) {
            state.isOpenAside = payload;
        },
        setNotice (state, { name, message, type }) {
            state.notice = { name, message, type };
        },
        deleteNotice (state, { name }) {
            state.notice = null;
        },
        setHeaderHeight (state, { headerHeight }) {
            state.headerHeight = headerHeight;
        },
        setIsMediaAtLeast720 (state) {
            state.isMediaAtLeast720 = isMediaAtLeast720();
        },
        setIsShowServerSwitcher (state, { isShowServerSwitcher }) {
            state.isShowServerSwitcher = isShowServerSwitcher;
        },
        setPageTabs (state, { tabs }) {
            state.tabs = tabs;
        },
        setConfig (state, { config }) {
            state.config = config;
        },
    },
    getters  : {
        isMobile            : () => isMobile,
        isTunnelEnabled     : (state) => {
            return !state.config.isLicenseExpired && state.config.features[FEATURES.tunnel];
        },
        isUnavailableNode   : (state, getters) => {
            return getters.getIsUnavailableNode(state.streamStore.currentStream);
        },
        getIsUnavailableNode: (state) => (stream) => {
            return state.config
                && state.config.clusterEnabled
                && stream
                && state.config.nodeId !== stream.node.id
                && !stream.node.available;
        },
    },
    actions  : {
        // Init Page
        createAdmin ({ commit, dispatch }, { settings }) {
            commit('setCreatingAdminState', { value: true });

            httpClient
                .post(UNLOCK_URL, settings)
                .then((res) => {
                    commit('deleteError', { name: CREDENTIALS_ERR });
                    router.replace({ name: PAGES.ALL_STREAMS });
                })
                .finally(() => {
                    commit('setCreatingAdminState', { value: false });
                });
        },
        // Auth Page
        auth ({ commit, dispatch }, { credentials }) {
            commit('setAuthState', { value: true });

            httpClient
                .post(SIGN_IN_URL, credentials)
                .then((res) => {
                    commit('deleteError', { name: CREDENTIALS_ERR });

                    const redirectPage = res.data.mustChangePassword ? PAGES.PROFILE_EDIT : PAGES.ALL_STREAMS;

                    router.replace(destinationPageStorage.getItem() || {
                        name  : redirectPage,
                        params: { mustChangePassword: res.data.mustChangePassword },
                    });
                    destinationPageStorage.removeItem();
                })
                .finally(() => {
                    commit('setAuthState', { value: false });
                });
        },
        logout ({ dispatch }) {
            httpClient
                .post(SIGN_OUT_URL)
                .then(() => {
                    dispatch('profile/clearUser');
                    destinationPageStorage.removeItem();
                    router.replace({ name: PAGES.AUTH });
                })
                .finally();
        },
        hasPermission () {
            return !!getCookie('token');
        },
        async checkPermission ({ dispatch, commit }) {
            return httpClient
                .get(HEALTH_URL)
                .then((res) => {
                    dispatch('updateVersion', { version: res.data.version });

                    if (res.data.warnings) {
                        commit('setHealthWarnings', { healthWarnings: res.data.warnings });
                    }
                    return { status: true };
                })
                .catch((error) => ({ statusCode: error.response.status, status: false }));
        },
        async fetchHealth ({ commit, state, dispatch }) {
            if (state.version) {
                return;
            }
            await httpClient
                .get(HEALTH_URL)
                .then((res) => {
                    dispatch('updateVersion', { version: res.data.version });

                    if (res.data.warnings) {
                        commit('setHealthWarnings', { healthWarnings: res.data.warnings });
                    }
                });
        },
        updateVersion ({ commit, state, dispatch }, { version }) {
            commit('setVersion', { version });
        },

        changeAsideState ({ commit }, payload) {
            commit('setAsideState', payload);
            saveToLocalStorage({
                key  : 'isOpenAside',
                value: payload,
                page : PAGES.ALL_STREAMS,
            });
        },
        toggleFoundingPageState ({ commit }) {
            commit('setFoundingPageState');
        },
        getPageSettingFromLocalStorage ({ commit }) {
            const streamsPageSettings = JSON.parse(localStorage.getItem(PAGES.ALL_STREAMS));

            if (streamsPageSettings) {
                commit('setAsideState', streamsPageSettings.isOpenAside);
            }
        },
        setError ({ commit, state }, { errorMsg, errorName }) {
            commit('setError', { name: errorName, error: errorMsg });
        },
        deleteError ({ commit }, { errorName }) {
            commit('deleteError', { name: errorName });
        },
        setNotice ({ commit, state, dispatch }, { name, message }) {
            commit('setNotice', { name, message });
        },
        deleteNotice ({ commit }, { name }) {
            commit('deleteNotice', { name });
        },
        resizeApp ({ commit }) {
            commit('setIsMediaAtLeast720');
        },

        fetchSettings ({ commit }) {
            return httpClient
                .get(SYSTEM_SETTINGS_URL)
                .then((res) => {
                    commit('setConfig', { config: res.data });
                    return res.data;
                });
        },
    },
});
