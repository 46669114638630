/* eslint-disable no-undef */

// Browser environment sniffing
const inBrowser    = typeof window !== 'undefined';
const inWeex       = typeof WXEnvironment !== 'undefined' && !!WXEnvironment.platform;
const weexPlatform = inWeex && WXEnvironment.platform.toLowerCase();
const UA           = inBrowser && window.navigator.userAgent.toLowerCase();
const isEdge       = UA && UA.indexOf('edge/') > 0;
const isAndroid    = (UA && UA.indexOf('android') > 0) || (weexPlatform === 'android');
const isIOS        = (UA && /iphone|ipad|ipod|ios/.test(UA)) || (weexPlatform === 'ios');
const isChrome     = UA && /chrome\/\d+/.test(UA) && !isEdge;
const isPhantomJS  = UA && /phantomjs/.test(UA);
const isFF         = UA && UA.match(/firefox\/(\d+)/);

function isSafari () {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.indexOf('safari') !== -1) {
        return userAgent.indexOf('chrome') <= -1;
    }
    return false;
}

function iOSVersion () {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
        if (window.indexedDB) {
            return 'iOS 8 and up';
        }
        if (window.SpeechSynthesisUtterance) {
            return 'iOS 7';
        }
        if (window.webkitAudioContext) {
            return 'iOS 6';
        }
        if (window.matchMedia) {
            return 'iOS 5';
        }
        if (!!window.history && 'pushState' in window.history) {
            return 'iOS 4';
        }
        return 'iOS 3 or earlier';
    }

    return null;
}

const isMobile = isAndroid || isIOS;

const isMediaAtLeast720 = () => window.matchMedia('(min-width: 720px)').matches;

export {
    isEdge,
    isAndroid,
    isIOS,
    isChrome,
    isPhantomJS,
    isFF,
    isSafari,
    iOSVersion,
    isMobile,
    isMediaAtLeast720,
};
