const RANGE_TYPES = {
    LIVE    : 'live',
    GAP     : 'gap',
    FRAGMENT: 'fragment',
};

const ANNOTATION_TYPES = {
    EVENT: 'event',
};

const THUMB = 'thumb';

const SPEED_INDEXES = [0.25, 0.5, 1, 2, 4, 8, 16];

export {
    RANGE_TYPES,
    THUMB,
    SPEED_INDEXES,
    ANNOTATION_TYPES,
};
