import {
    ATTENTION,
    ATTENTION_DANGER,
    ATTENTION_INFO,
    ATTENTION_WARNING,
    DANGER,
    SUCCESS,
    WARNING,
} from './names';

export default {
    [SUCCESS]          : { name: `alert-ycc-${SUCCESS}`, hasIcon: false },
    [DANGER]           : { name: `alert-ycc-${DANGER}`, hasIcon: false },
    [WARNING]          : { name: `alert-ycc-${WARNING}`, hasIcon: false },
    [ATTENTION]        : { name: `alert-ycc-${ATTENTION}`, hasIcon: true },
    [ATTENTION_INFO]   : { name: `alert-ycc-${ATTENTION_INFO}`, hasIcon: true },
    [ATTENTION_WARNING]: { name: `alert-ycc-${ATTENTION_WARNING}`, hasIcon: true },
    [ATTENTION_DANGER] : { name: `alert-ycc-${ATTENTION_DANGER}`, hasIcon: true },
};
